import React, { Fragment, useState } from 'react';
import { Button, Card, Container, ContainerFlex, TextLocalized } from '../styled/Styled';
import { useHistory } from 'react-router';
import { getQuiz, resetQuiz } from '../util/QuizUitl';

import canvas from '../../images/rscanvas.jpg';
import Icon from '@mdi/react';
import { mdiArrowExpandAll, mdiClose } from '@mdi/js';

export const ModalImage = ({ setShowModal }) => {
    return <ContainerFlex position="fixed" justify="center" align="center" top="0" left="0" width="12" height="100vh" color="color-gray-alpha" elevation="60" overflowY="hidden">
        <ContainerFlex fontColor="color-white" px="16" onClick={() => setShowModal(false)} width="12" justify="flex-end" children={<Button children={<Icon path={mdiClose} size={1} />} />} />
        <img alt="rscanvas" width="70%" src={canvas} />
    </ContainerFlex>
}

const BlockItem = ({ item }) => {
    let history = useHistory();

    const getProgress = (index) => {
        let numAnswers = 0;
        let numQuiz = 0;

        item.categories[index].groups.map(group => {
            //Add num quizs
            numQuiz += group.quizs.length;
            group.quizs.map(quiz => {
                //Increment num answers
                if (quiz.answers.filter(answ => answ.selected).length > 0)
                    numAnswers += 1;
                return quiz;
            })
            return group;
        });

        let result = (numAnswers / numQuiz * 100).toFixed(0);
        return isNaN(result) ? 100 : result;
    }

    return <Container width="12" sm="6" lg="4" px="4">
        <Card flex={true} direction="column" shadowOn={true} width="12" height="100%">
            <ContainerFlex expand={true}>
                <Container>
                    <Container width="12" minheight="120px" image={item.image} />
                    <Container width="12" ph="16" pv="8" fontColor="color-gray" children={<TextLocalized children="msgArea" />} />
                    <Container width="12" ph="16" fontSize="18px" fontWeight="bold" children={<TextLocalized children={item.title} />} />
                    <Container width="12" ph="16" pv="16" children={<TextLocalized children={item.description_short} />} />
                </Container>
            </ContainerFlex>
            <ContainerFlex align="center" pb="16">
                <Container width="12" ph="16" pv="8" fontColor="color-gray" children={<TextLocalized children="msgProgress" />} />
                <ContainerFlex width="12" justify="flex-start" pb="16">
                    <Card flex={true} justify="center" align="center" mh="16" border="solid" borderColor="color-economic" borderWidth="2px" radius="50%"
                        fontColor="color-black" fontWeight="bold"
                        minheight="54px" minwidth="54px" children={`${getProgress(0)}%`} />

                    <Card flex={true} justify="center" align="center" mh="16" border="solid" borderColor="color-social" borderWidth="2px" radius="50%"
                        fontColor="color-black" fontWeight="bold"
                        minheight="54px" minwidth="54px" children={`${getProgress(1)}%`} />

                    <Card flex={true} justify="center" align="center" mh="16" border="solid" borderColor="color-ecolo" borderWidth="2px" radius="50%"
                        fontColor="color-black" fontWeight="bold"
                        minheight="54px" minwidth="54px" children={`${getProgress(2)}%`} />
                </ContainerFlex>

                <Button onClick={() => history.push("/section", { item: item })}
                    width="wrap" mh="8" pv="8" color="color-blue" hoverColor="color-blue-dark"
                    fontColor="color-white" fontWeight="bold" textAlign="center"
                    children={<TextLocalized children={getProgress(0) === "0" && getProgress(1) === "0" && getProgress(2) === "0" ? "actionStart" : "actionContinue"} />} />
            </ContainerFlex>
        </Card>
    </Container>
}

const HomeView = () => {
    const [quiz, setQuiz] = useState(getQuiz());
    const [showModal, setShowModal] = useState(false);
    let history = useHistory();

    const showResults = () => {
        //TODO Check is complete
        history.push('/results')
    }

    const reset = () => {
        let quizAux = resetQuiz();
        setQuiz(quizAux);
    }

    return (
        <Fragment>
            <ContainerFlex width="12" justify="center" minheight="calc(100vh - 92px)">
                <ContainerFlex width="12" lg="8" xl="8" justify="center" >
                    <Container width="12" textAlign="center" fontWeight="bold" fontSize="28px" fontColor="color-gray" children={<TextLocalized children="homeTitle" />} />
                    <Card mv="16" minwidth="200px" height="5px" color="color-blue" radius="2px" />

                    <Card shadowOn={true} flex={true} mt="32" align="stretch">
                        <Container width="wrap" pv="32" ph="16" children={<TextLocalized children="homeDescription" />} />
                        <ContainerFlex direction="column" width="3" fontColor="color-blue">
                            <Button flex={true} expand={true} width="12" onClick={() => setShowModal(true)} image={canvas} />
                            <ContainerFlex pv="8">
                                <Icon path={mdiArrowExpandAll} size={1} />
                                <Container pl="16" width="wrap" fontSize="14px" children={<TextLocalized children="msgClickImage" />} />
                            </ContainerFlex>
                        </ContainerFlex>
                    </Card>

                    <Container width="12" mt="32" fontSize="18px" fontWeight="300" fontColor="color-gray" children={<TextLocalized children="msgBusinessBloq" />} />
                    <Card mv="16" width="12" height="5px" color="color-blue" radius="2px" />

                    <ContainerFlex width="12" align="stretch" children={quiz.map((item) => <BlockItem key={item.id} item={item} />)} />

                    <Button onClick={reset} shadowOn={true} width="wrap" mv="32" mh="16" pv="16" color="color-red" hoverColor="color-blue-dark"
                        fontColor="color-white" fontWeight="bold" textAlign="center"
                        children={<TextLocalized children="actionReset" />} />

                    <Button onClick={showResults} shadowOn={true} width="wrap" mv="32" mh="16" pv="16" color="color-blue" hoverColor="color-blue-dark"
                        fontColor="color-white" fontWeight="bold" textAlign="center"
                        children={<TextLocalized children="actionDoDiagnost" />} />
                </ContainerFlex>
            </ContainerFlex>
            {showModal ? <ModalImage setShowModal={setShowModal} /> : null}
        </Fragment>


    );
};

export default HomeView;