const es = {

    "homeTitle": "Business Modeling Design for Shared Value in Contract-hospitality",
    "homeDescription": "Las empresas fabricantes del canal contract están mostrando un compromiso cada vez mayor por el desarrollo de actividades con un impacto social y medioambiental positivo. Sin embargo, solo una parte de estas empresas están sabiendo canalizar de una manera consciente e integrada el efecto de estas actividades socialmente responsables hacia la generación de nuevas fuentes de competitividad empresarial (Creación de Valor Compartido, CVC).</br></br>Ahora puedes utilizar la herramienta de diagnóstico RSContract desarrollada por CENFIM Furnishings Cluster para evaluar el impacto del modelo de negocio de tu empresa respecto a las principales dimensiones de análisis de una <b>Empresa Contract de Triple Impacto</b>: i) el valor económico y organizativo, ii) la conciencia societal y el desarrollo de comunidades locales, y iii) el impacto ambiental.",

    "msgBusinessBloq": "Bloques de negocio",
    "msgArea": "ANÁLISIS DE VALOR COMPARTIDO",
    "msgEval": "EVALUACIÓN",
    "msgResume": "RESUMEN",
    "msgProgress": "PROGRESO",
    "msgPrivacy": "Política de privacidad",
    "msgContact": "Contacto",
    "msgCopy": "©2021 CENFIM",

    "msgCat1": "Organización / Negocio - ECONOMIC",
    "msgCat2": "Comunidad local e impacto societal - SOCIAL",
    "msgCat3": "Entorno natural / Medioambiente - ECOLOGICAL",

    "msgSection1": "Trabajadores",
    "msgSection1DescShort": "Descubrimos qué puede hacer nuestra empresa para favorecer el bienestar financiero, personal, profesional y social de sus empleados, así como utilizar la contratación de personal como herramienta estratégica para contribuir al desarrollo sostenible de las comunidades en las que la empresa opera.",
    "msgSection1Desc": "Los empleados o trabajadores son uno de los ejes esenciales para el desarrollo del negocio de las empresas hábitat que operan en el canal contract. El área de impacto \"Trabajadores\" evalúa en qué medida la empresa contribuye al bienestar financiero, profesional, profesional y social de sus empleados, abordando temas como la salud y el bienestar laboral, el empoderamiento de los empleados, su involucración en la comunidad local o el impacto medioambiental de cada puesto de trabajo, entre otros.<br/><br/>Responde a las siguientes preguntas para obtener una primera representación del análisis de triple impacto de la compañía en este bloque de negocio.",

    "msgSection2": "Gobernanza y estructura",
    "msgSection2DescShort": "Descubrimos qué puede hacer nuestra empresa para mejorar las políticas y prácticas relacionadas con su misión, su ética, su responsabilidad y su transparencia.",
    "msgSection2Desc": "Las empresas conscientes están liderando la transformación de la estructura organizativa del sector hacia un compromiso real e integrado por la responsabilidad, la sostenibilidad y la conciencia social.  Este bloque de negocio evalúa la misión, la ética, la responsabilidad y la transparencia de la empresa y aborda temas como la integración de objetivos sociales y ambientales en las evaluaciones de desempeño de los empleados, la divulgación del impacto y su transparencia y la participación de las partes interesadas, entre otros.<br/><br/>Responde a las siguientes preguntas para obtener una primera representación del análisis de triple impacto de la compañía en este bloque de negocio.",

    "msgSection3": "Recursos clave",
    "msgSection3DescShort": "Descubrimos qué puede hacer nuestra empresa para introducir una estrategia de triple impacto en el acceso a recursos materiales o inmateriales que son necesarios para la gestión y producción diaria de las actividades.",
    "msgSection3Desc": "Hoy las empresas del canal contract protegen con más énfasis los recursos naturales y los materiales utilizados en sus procesos, y realizan una inversión más sostenible en la adquisición y mantenimiento de espacios de trabajo. Este bloque de negocio permite caracterizar los principales recursos utilizados por la empresa para la gestión y producción diaria de sus actividades.<br/><br/>Responde a las siguientes preguntas para obtener una primera representación del análisis de triple impacto de la compañía en este bloque de negocio.",

    "msgSection4": "Actividades y procesos",
    "msgSection4DescShort": "Descubrimos qué puede hacer nuestra empresa para incorporar una estrategia de valor compartido durante el desarrollo de las principales actividades realizadas para cubrir las necesidades y preferencias de nuestros clientes y beneficiarios.",
    "msgSection4Desc": "Las empresas del canal de distribución contract están redefiniendo sus procesos productivos para hacer una mejor gestión de sus operaciones internas mientras mejoran las condiciones del territorio en el que operan y reducen su impacto medioambiental en el entorno.  Este bloque de negocio permite analizar las principales actividades y procesos utilizados por la empresa para generar una propuesta de valor capaz de satisfacer las necesidades y deseos de sus clientes y beneficiarios.<br/><br/>Responde a las siguientes preguntas para obtener una primera representación del análisis de triple impacto de la compañía en este bloque de negocio.",

    "msgSection5": "Cadena de suministro y aliados",
    "msgSection5DescShort": "Descubrimos qué puede hacer nuestra empresa para configurar la relación con sus proveedores y aliados de manera que podamos extender el impacto generado por la compañía durante el proceso de generación de valor. ",
    "msgSection5Desc": "La empresas responsables del canal contract mantienen una relación próxima y sensible con sus proveedores y colaboradores.  Este bloque de negocio permite caracterizar la relación de la empresa con su cadena de suministro involucrada en el proceso de generación de valor, haciendo hincapié en el impacto de esta red en la estrategia de valor compartido.<br/><br/>Responde a las siguientes preguntas para obtener una primera representación del análisis de triple impacto de la compañía en este bloque de negocio.",

    "msgSection6": "Propuesta de valor",
    "msgSection6DescShort": "Descubrimos qué puede hacer nuestra empresa para mejorar el valor que aporta a los clientes y beneficiarios directos del canal contract, haciendo un mayor énfasis en el valor social y medioambiental de nuestra propuesta de valor.",
    "msgSection6Desc": "El desarrollo sostenible de las empresas contract debe reflejar de una manera directa e innovadora los efectos de su offering en el fortalecimiento de las condiciones sociales y medioambientales de las comunidades en las que opera la compañía. Este bloque de negocio centra su foco en la definición del valor principal que la empresa ofrece a sus clientes, beneficiarios y stakeholders para involucrarlos.<br/><br/>Responde a las siguientes preguntas para obtener una primera representación del análisis de triple impacto de la compañía en este bloque de negocio. ",

    "msgSection7": "Clientes y beneficiarios",
    "msgSection7DescShort": "Descubrimos cómo nuestra empresa contract puede desarrollar su estrategia de valor compartido durante la interacción de clientes y beneficiarios con nuestros productos y servicios.",
    "msgSection7Desc": "Las empresas de impacto del canal contract se relacionan con sus clientes y beneficiarios de una manera responsable, inclusiva y empoderada. En este bloque de negocio evaluamos el impacto que tienen nuestros productos y servicios sobre diferentes segmentos de clientes y usuarios, así como los usos realizados por éstos como parte de la la gestión del valor compartido creado por la empresa.<br/><br/>Responde a las siguientes preguntas para obtener una primera representación del análisis de triple impacto de la compañía en este bloque de negocio. ",

    "msgSection8": "Stakeholders",
    "msgSection8DescShort": "Descubrimos cómo nuestra empresa puede extender la relación con diferentes grupos de interés a través de su propuesta de valor compartido.",
    "msgSection8Desc": "Las empresas contract cultivan la colaboración con diferentes grupos de interés para mejorar las condiciones en las que se entrega y es experimentada su propuesta de valor compartido. Este bloque de negocio nos permite centrarnos en la interacción con estos stakeholders, revisando el impacto de nuestro offering principal más allá de la relación habitual con nuestros clientes y beneficiarios.<br/><br/>Responde a las siguientes preguntas para obtener una primera representación del análisis de triple impacto de la compañía en este bloque de negocio.",

    "msgSection9": "Espacios de relación",
    "msgSection9DescShort": "Descubrimos cómo podemos extender nuestra estrategia de valor compartido a través de los espacios y medios del canal contract utilizados por la empresa para comunicarnos e interactuar con nuestros clientes, beneficiarios y stakeholders.",
    "msgSection9Desc": "Las empresas contract que crean valor compartido apuestan por un desarrollo más sostenible, consciente y humanizado de las relaciones con clientes, beneficiarios y stakeholders. En este bloque de negocio analizamos los espacios de relación habituales en el canal de distribución contract utilizados por la empresa para dar a conocer su propuesta de valor, distribuir sus productos, concretar su venta y establecer nuevos intercambios de valor en la post-venta.<br/><br/>Responde a las siguientes preguntas para obtener una primera representación del análisis de triple impacto de la compañía en este bloque de negocio.",

    /** SECTION 1 */
    "msgSec1Cat3Gr1": "Puesto de trabajo sostenible",
    "msgSec1Cat3Gr1Qz1": "¿Toma medidas la empresa para incentivar que los empleados no utilicen su vehículo particular para acudir a su puesto de trabajo?",

    "msgSec1Cat3Gr2": "Formación y sensibilización en materia ambiental",
    "msgSec1Cat3Gr2Qz1": "¿Realiza la empresa actividades de formación o sensibilización en ámbito medioambiental para empleados?",

    "msgSec1Cat2Gr1": "Empleados potenciales",
    "msgSec1Cat2Gr1Qz1": "¿Cuenta la empresa cuenta con alguna política activa de contratación de personas procedentes de colectivos desfavorecidos y/o en riesgo de exclusión social con dificultades de inserción laboral (minorías étnicas, inmigrantes, personas con discapacidad, personas desempleadas de larga duración, entre otros)?",
    "msgSec1Cat2Gr1Qz2": "¿La empresa colabora con entidades locales o regionales para fomentar la creación de ocupación en la zona (centros de formación profesional, universidades, servicios de ocupación, entre otros)?",

    "msgSec1Cat2Gr2": "Promoción del voluntariado y la acción social",
    "msgSec1Cat2Gr2Qz1": "¿Colabora la empresa con entidades locales sin ánimo de lucro para llevar a cabo actividades de voluntariado tanto para los directivos como para el resto de empleados?",

    "msgSec1Cat2Gr3": "Desarrollo de recursos compartidos con otras entidades del territorio",
    "msgSec1Cat2Gr3Qz1": "¿Dispone la empresa de un servicio de guardería compartido con las empresas de la zona para que los empleados puedan llevar a sus hijos e hijas?",

    "msgSec1Cat2Gr4": "Fomento de la participación de los trabajadores en redes territoriales y sectoriales",
    "msgSec1Cat2Gr4Qz1": "¿La empresa fomenta y favorece que sus empleados participen en redes, grupos de trabajo y/o proyectos de innovación social en el territorio?",

    "msgSec1Cat1Gr1": "Seguridad, salud y bienestar laboral - Espacio de trabajo saludable",
    "msgSec1Cat1Gr1Qz1": "¿Dispone la empresa de una Política de Seguridad y Salud Laboral con funciones y responsabilidades definidas en materia preventiva?",
    "msgSec1Cat1Gr1Qz2": "¿Dispone la empresa de políticas y procedimientos para evaluar necesidades y activar medidas dirigidas a garantizar un entorno de trabajo saludable (iluminación, ergonomía, nutrición, entre otros)?",

    "msgSec1Cat1Gr2": "Capacitación y desarrollo de competencias de los trabajadores",
    "msgSec1Cat1Gr2Qz1": "¿Asigna la empresa un presupuesto anual a la formación continua y la certificación de sus empleados?",

    "msgSec1Cat1Gr3": "Empoderamiento del trabajador",
    "msgSec1Cat1Gr3Qz1": "¿Tiene la empresa mecanismos para recoger sugerencias, consultas o quejas de los empleados y tenerlos en cuenta a la hora de tomar decisiones operativas o de negocio?",
    "msgSec1Cat1Gr3Qz2": "¿La empresa utiliza metodologías y herramientas que favorecen la creatividad, la innovación, el el trabajo en equipo por parte de los trabajadores?",

    "msgSec1Cat1Gr4": "Igualdad de oportunidades e inclusión",
    "msgSec1Cat1Gr4Qz1": "¿Obliga la empresa a todos sus empleados a realizar formación en prevención de acoso sexual, conductas discriminatorias por motivo de género, raza, discapacidad, u orientación sexual?",

    "msgSec1Cat1Gr5": "Calidad de vida",
    "msgSec1Cat1Gr5Qz1": "¿Dispone la empresa de medidas para mejorar la conciliación de vida laboral y personal a los empleados, incluyendo permisos parentales, teletrabajo, horarios flexibles, jornadas partidas o entre otros?",
    "msgSec1Cat1Gr5Qz2": "En situaciones de desvinculación laboral, ¿ofrece la empresa a sus empleados programas de recolocación (outplacement) para reorientar su perfil profesional y capacitarles para reincorporarse al mercado laboral en el menor tiempo posible?",

    /** SECTION 2 */
    "msgSec2Cat3Gr1": "Estrategia / cultura ambiental",
    "msgSec2Cat3Gr1Qz1": "¿Ha implantado la empresa algún Sistema de Gestión Ambiental sometido sistemáticamente a auditorías internas ambientales y certificado según UNE - EN ISO 14001?",

    "msgSec2Cat3Gr2": "Apoyo a la sensibilización",
    "msgSec2Cat3Gr2Qz1": "¿Reporta la empresa sus actividades en materia medioambiental en su memoria de sostenibilidad?",

    "msgSec2Cat3Gr3": "Co-financiación de proyectos medioambientales",
    "msgSec2Cat3Gr3Qz1": "¿Financia la empresa algún tipo de programa de ámbito medioambiental, ya sea en solitario o con otras entidades?",

    "msgSec2Cat2Gr1": "Donaciones e inversiones destinadas a la comunidad",
    "msgSec2Cat2Gr1Qz1": "¿Contribuye la empresa a financiar proyectos o programas relacionados con su sector y que generan impacto positivo tanto en el negocio como en el territorio?",

    "msgSec2Cat2Gr2": "Fomento de la implicación y colaboración con actores locales",
    "msgSec2Cat2Gr2Qz1": "¿Tiene la empresa convenios de colaboración firmados con las administraciones públicas, asociaciones empresariales u organizaciones sociales locales en las zonas desde las que la empresa opera?",

    "msgSec2Cat2Gr3": "Alcance y estructura geográfica",
    "msgSec2Cat2Gr3Qz1": "¿Se toman medidas para que la estructura geográfica de la empresa no limite el compromiso local de la organización con las comunidades en las que ésta opera?",

    "msgSec2Cat2Gr4": "Diversidad de la participación societaria",
    "msgSec2Cat2Gr4Qz1": "¿Hay personas de grupos vulnerables o infrarrepresentados con participaciones mayoritarias en el accionariado de la empresa?",

    "msgSec2Cat2Gr5": "Adhesión a pactos globales",
    "msgSec2Cat2Gr5Qz1": "¿Incorpora la empresa información sobre los Objetivos de Desarrollo Sostenible a sus procesos existentes y en sus actuales memorias corporativas?",

    "msgSec2Cat1Gr1": "Gestión interna",
    "msgSec2Cat1Gr1Qz1": "¿Dispone la empresa de códigos de buen gobierno que incorporen aspectos sociales, medioambientales y éticos, y mecanismos para garantizar su aplicación sistemática?",
    "msgSec2Cat1Gr1Qz2": "¿Incorpora la empresa las exigencias legales en materia de desarrollo sostenible de sus actividades y da una respuesta eficaz a los requerimientos de la Administración Pública?",

    "msgSec2Cat1Gr2": "Estrategia de RSC",
    "msgSec2Cat1Gr2Qz1": "¿A qué nivel considera que la Dirección de la empresa apoya y supervisa el desarrollo de acciones en materia de RSC y fomenta la participación de todas las áreas de negocio en el desarrollo de la RSC?",
    "msgSec2Cat1Gr2Qz1Ans1": "Nunca o casi nunca",
    "msgSec2Cat1Gr2Qz1Ans2": "Algunas veces",
    "msgSec2Cat1Gr2Qz1Ans3": "Bastantes veces",
    "msgSec2Cat1Gr2Qz1Ans4": "Casi siempre",
    "msgSec2Cat1Gr2Qz1Ans5": "Siempre",

    "msgSec2Cat1Gr3": "Empoderamiento y liderazgo participativo",
    "msgSec2Cat1Gr3Qz1": "¿Cuáles de las siguientes acciones toma la empresa para promover el liderazgo participativo entre los trabajadores durante el desarrollo de proyectos?",
    "msgSec2Cat1Gr3Qz1Ans1": "Fomento del trabajo en equipo de manera sistemática.",
    "msgSec2Cat1Gr3Qz1Ans2": "Participación de las personas en la definición de objetivos y planes estratégicos y operativos.",
    "msgSec2Cat1Gr3Qz1Ans3": "Se promueve la participación activa en la búsqueda de mejoras en la gestión y en la detección de nuevas oportunidades.",
    "msgSec2Cat1Gr3Qz1Ans4": "Ninguna de las anteriores.",

    "msgSec2Cat1Gr4": "Comunicación interna y gestión del conocimiento",
    "msgSec2Cat1Gr4Qz1": "¿Cuál de las siguientes afirmaciones considera que describe mejor la comunicación interna y gestión del conocimiento de su organización?",
    "msgSec2Cat1Gr4Qz1Ans1": "La organización tiene una política de transparencia informativa interna que garantizan que todo el personal recibe información concisa, completa y comprensible sobre aspectos claves como la situación económica, cuestiones laborales, alianzas, cambios organizacionales, etc.",
    "msgSec2Cat1Gr4Qz1Ans2": "Se dispone de canales que teóricamente garantizan la comunicación e intercambio de información en sentido ascendente y descendente, vertical y horizontal, pero ésta no circula de manera fluida, ágil y constante. No se fomenta el intercambio de información más que de forma puntual o reactiva.",
    "msgSec2Cat1Gr4Qz1Ans3": "La organización tiene una política de transparencia informativa y se fomenta de forma el intercambio de información y la transferencia de conocimiento mediante espacios y herramientas para compartir y desarrollar nuevos aprendizajes de manera permanente.",
    "msgSec2Cat1Gr4Qz1Ans4": "La organización no tiene una política de comunicación o transparencia interna ni de gestión del conocimiento.",

    "msgSec2Cat1Gr5": "Transformación digital de la organización",
    "msgSec2Cat1Gr5Qz1": "Por favor indique el nivel de madurez que considera que la organización tiene actualmente en su proceso de transformación digital",
    "msgSec2Cat1Gr5Qz1Ans1": "La organización no tiene ninguna estrategia de transformación digital.",
    "msgSec2Cat1Gr5Qz1Ans2": "La organización ha empezado a dar pasos hacia un proceso de transformación digital, pero sin ningún plan concreto ni presupuesto asignado.",
    "msgSec2Cat1Gr5Qz1Ans3": "La organización tiene un plan de transformación digital y un presupuesto asignado al mismo, pero no dispone de métricas para hacer seguimiento del mismo.",
    "msgSec2Cat1Gr5Qz1Ans4": "La organización tiene un plan de transformación digital sólido y un presupuesto asignado al mismo, con métricas de seguimiento y que apuesta decididamente por la investigación, desarrollo e innovación.",
    "msgSec2Cat1Gr5Qz1Ans5": "La organización hace años que ejecuta y monitoriza un plan de transformación digital, asignando un presupuesto anual al mismo, que tiene a la investigación, desarrollo e innovación como ejes centrales.",

    /** SECTION 3 */
    "msgSec3Cat3Gr1": "Control de consumos de recursos naturales",
    "msgSec3Cat3Gr1Qz1": "¿Dispone su organización de sistemas para medir, registrar y el consumo de recursos naturales tanto en sus operaciones como por parte del personal?",
    "msgSec3Cat3Gr1Qz1Ans1": "La organización dispone de sistemas para medir el consumo de recursos hídricos.",
    "msgSec3Cat3Gr1Qz1Ans2": "La organización dispone de sistemas para medir el uso eficiente de energía en sus instalaciones.",
    "msgSec3Cat3Gr1Qz1Ans3": "La organización dispone de sistemas para medir el porcentaje de uso de fuentes de energía renovables.",
    "msgSec3Cat3Gr1Qz1Ans4": "La organización ha instalado iluminación de bajo consumo o sensores de presencia que permiten reducir el consumo de energía de las instalaciones.",
    "msgSec3Cat3Gr1Qz1Ans5": "La organización no dispone de ninguno de los sistemas arriba descritos.",

    "msgSec3Cat3Gr2": "Materias primas y materiales",
    "msgSec3Cat3Gr2Qz1": "Por favor marque la/s respuesta/s que apliquen",
    "msgSec3Cat3Gr2Qz1Ans1": "La empresa prioriza la compra y uso de productos y materiales reciclados y de aquellos que permiten más de un uso.",
    "msgSec3Cat3Gr2Qz1Ans2": "La empresa adquiere productos que no tienen efectos nocivos contra la salud y el medioambiente (por ejemplo, el uso de sprays).",
    "msgSec3Cat3Gr2Qz1Ans3": "La empresa almacena correctamente los materiales para conservar la calidad y evitar riesgos de contaminación (por vertidos o evaporación).",
    "msgSec3Cat3Gr2Qz1Ans4": "La empresa tiene una política que prioriza la compra de productos con ecoetiquetas o certificaciones ecológicas.",
    "msgSec3Cat3Gr2Qz1Ans5": "La empresa no tiene ninguna política de sostenibilidad medioambiental relativa a las compras, fabricación, almacenamiento, reutilización o reciclaje de productos y/o suministros.",

    "msgSec3Cat3Gr3": "Gestión medioambiental de espacios",
    "msgSec3Cat3Gr3Qz1": "Por favor marque la/s respuesta/s que apliquen",
    "msgSec3Cat3Gr3Qz1Ans1": "Las instalaciones de empresa tienen una certificación de cumplimiento con estándares de construcción ecológica.",
    "msgSec3Cat3Gr3Qz1Ans2": "La empresa invierte en programas para fomentar el reciclaje de residuos en sus instalaciones y en la gestión de las oficinas virtuales de sus empleados (p.e. papeleras y contenedores de recogida selectiva).",
    "msgSec3Cat3Gr3Qz1Ans3": "La empresa adquiere productos de bajo impacto ambiental para el mantenimiento de sus oficinas (p.e. productos de limpieza no tóxicos, productos de papel sin cloro o sin blanquear, suministros de oficina reciclados, etc.)",
    "msgSec3Cat3Gr3Qz1Ans4": "La organización utiliza los recursos ofimáticos e informáticos de manera eficiente para reducir su impacto medioambiental (p.e. gestión documental orientada a “cero utilización de papel”, uso de servicios de almacenamiento de datos en la nube, etc.).",
    "msgSec3Cat3Gr3Qz1Ans5": "La empresa no tiene ninguna política de gestión medioambiental de sus instalaciones.",

    "msgSec3Cat2Gr1": "Trazabilidad social e impacto local de los recursos",
    "msgSec3Cat2Gr1Qz1": "Por favor marque la/s respuesta/s que apliquen",
    "msgSec3Cat2Gr1Qz1Ans1": "La empresa mide el porcentaje de materias primas compradas a proveedores locales sobre el total de compras.",
    "msgSec3Cat2Gr1Qz1Ans2": "La empresa sigue criterios socialmente responsables para seleccionar la adquisición de recursos clave de la organización.",
    "msgSec3Cat2Gr1Qz1Ans3": "La empresa fomenta el uso de recursos clave de Km0.",
    "msgSec3Cat2Gr1Qz1Ans4": "La empresa identifica y mide los impactos de sus inversiones en recursos en el entorno local en términos de mejora social, actividad económica, generación de empleo y sostenibilidad medioambiental.",
    "msgSec3Cat2Gr1Qz1Ans5": "La empresa no dispone de políticas o mecanismos para medir la trazabilidad social o el impacto local de sus actividades.",

    "msgSec3Cat2Gr2": "Activos compartidos",
    "msgSec3Cat2Gr2Qz1": "¿Cede o comparte la empresa espacios, recursos físicos, digitales o humanos con otras entidades del territorio en el que opera?",

    "msgSec3Cat2Gr3": "Desarrollo de capacidades en el territorio",
    "msgSec3Cat2Gr3Qz1": "¿Invierte la empresa en la capacitación de las personas que viven en el territorio en el que opera para el desarrollo de sus talentos y habilidades?",

    "msgSec3Cat1Gr1": "Recursos físicos",
    "msgSec3Cat1Gr1Qz1": "Enumera los recursos físicos (tangibles) esenciales, los recursos intangibles, los recursos humanos (personas) y los recursos financieros que utiliza la empresa para crear su propuesta de valor (servicios, productos, actividades). ",
    "msgSec3Cat1Gr1Qz1Ans1": "Escribe tu respuesta en el recuadro de comentario",

    /** SECTION 4 */
    "msgSec4Cat3Gr1": "Prevención y gestión de residuos",
    "msgSec4Cat3Gr1Qz1": "Por favor, marque aquella respuesta que se ajuste mejor a la realidad de la compañía en estos momentos",
    "msgSec4Cat3Gr1Qz1Ans1": "La empresa dispone de sistemas para medir y gestionar la producción, recogida, reciclaje y eliminación de residuos (en toneladas métricas u otra medida), ajustándose a lo exigido por la normativa correspondiente.",
    "msgSec4Cat3Gr1Qz1Ans2": "La empresa dispone de sistemas para medir y gestionar la producción, reciclaje y eliminación de residuos (en toneladas métricas u otra medida), yendo más allá de lo exigido por la normativa correspondiente, ya que hay evidencias de búsqueda e implantación sistemática de acciones de mejora en la medición y gestión de residuos.",
    "msgSec4Cat3Gr1Qz1Ans3": "La empresa promueve la reducción en la producción de residuos, así como la recogida selectiva para su reutilización y reciclaje (R+R+R).",
    "msgSec4Cat3Gr1Qz1Ans4": "La empresa ha fijado una meta de cero desechos.",
    "msgSec4Cat3Gr1Qz1Ans5": "La empresa no dispone de políticas o sistemas para gestionar o medir la producción, recogida, reciclaje y eliminación de residuos. ",

    "msgSec4Cat3Gr2": "Medidas para el control de emisiones contaminantes y ruidos",
    "msgSec4Cat3Gr2Qz1": "Por favor, marque aquellas respuestas que apliquen según la realidad actual de la empresa",
    "msgSec4Cat3Gr2Qz1Ans1": "Como actividad potencialmente contaminante de la atmósfera, la empresa cumple con lo establecido por la normativa aplicable y utiliza indicadores para la medición y el control de niveles de emisión.",
    "msgSec4Cat3Gr2Qz1Ans2": "La empresa cumple con lo establecido por la normativa aplicable y utiliza indicadores para la medición y el control de niveles de ruido. ",
    "msgSec4Cat3Gr2Qz1Ans3": "La empresa adquiere bonos de carbono certificados para compensar parte de las emisiones de gases de efecto invernadero.",
    "msgSec4Cat3Gr2Qz1Ans4": "La empresa no dispone de políticas ni de medidas para cumplir con lo establecido por la normativa aplicable ni utiliza indicadores para medir o controlar niveles de emisión y ruidos.",

    "msgSec4Cat3Gr3": "Movilidad sostenible",
    "msgSec4Cat3Gr3Qz1": "Por favor, marque aquellas respuestas que apliquen",
    "msgSec4Cat3Gr3Qz1Ans1": "La empresa fomenta la movilidad sostenible en su flota de vehículos durante sus transportes y distribución (por ejemplo, capacita a los conductores y operadores con respecto a técnicas de uso eficiente de combustible)",
    "msgSec4Cat3Gr3Qz1Ans2": "La empresa utiliza software de planificación estratégica para minimizar el uso de combustible y la huella de carbono de los procesos de envío.",
    "msgSec4Cat3Gr3Qz1Ans3": "La empresa fomenta el uso de métodos de transporte o de envío con menor impacto ambiental (p. ej., evita el uso del transporte aéreo).",
    "msgSec4Cat3Gr3Qz1Ans4": "La empresa no tiene políticas de fomento de movilidad sostenible que minimice el uso de combustible y su huella de carbono.",

    "msgSec4Cat3Gr4": "Ciclo de vida, eco-diseño y economía circular",
    "msgSec4Cat3Gr4Qz1": "La empresa, ¿diseña sus productos y servicios intentando minimizar su impacto medioambiental durante todo el ciclo de vida del producto (diseño, extracción de materias primas, fabricación, almacenamiento, venta, uso por parte de clientes, gestión de residuos)?",

    "msgSec4Cat3Gr5": "Twin transition",
    "msgSec4Cat3Gr5Qz1": "¿La empresa invierte en el uso de tecnologías digitales para optimizar procesos y minimizar las emisiones globales de carbono, p.e. Inteligencia Artificial, robótica, etc.?",

    "msgSec4Cat2Gr1": "Actividades para la adaptación o creación de soluciones a colectivos infra-representados o desfavorecidos",
    "msgSec4Cat2Gr1Qz1": "¿Adapta la empresa su offering de productos y servicios a las necesidades de colectivos infra-representados o desfavorecidos?",

    "msgSec4Cat2Gr2": "Infraestructuras locales",
    "msgSec4Cat2Gr2Qz1": "¿Ha realizado la empresa inversiones para la mejora o el mantenimiento, de infraestructuras del territorio en el que opera?",

    "msgSec4Cat1Gr1": "Tecnologías de la Industria 4.0",
    "msgSec4Cat1Gr1Qz1": "¿Incorpora la empresa en sus procesos productivos avances tecnológicos derivados de la Industria 4.0, p.e. automatización y robotización de los procesos para hacerlos más eficientes?",

    "msgSec4Cat1Gr2": "Control de las operaciones financieras",
    "msgSec4Cat1Gr2Qz1": "¿Dispone la empresa de un plan de inversiones en actividades y procesos, y cuenta con sistemas de seguimiento y medición del cumplimiento de los objetivos de rentabilidad de las mismas?",

    /** SECTION 5 */
    "msgSec5Cat3Gr1": "Contratación de proveedores",
    "msgSec5Cat3Gr1Qz1": "¿Verifica o evalúa la empresa el impacto ambiental de sus proveedores significativos mediante sistemas de gestión ambiental como ISO 14001 o reglamento EMAS, o mediante sistemas de gestión de la calidad como ISO 9000 o EFQM?",

    "msgSec5Cat3Gr2": "Gestión ambiental del transporte de proveedores",
    "msgSec5Cat3Gr2Qz1": "¿Ha adoptado la empresa alguna práctica para minimizar el impacto ambiental del transporte en su cadena de suministro, como la utilización vehículos de energía limpia o de bajas emisiones (por ejemplo, vehículos híbridos, eléctricos o de gas licuado de petróleo)?",

    "msgSec5Cat2Gr1": "Co-desarrollo del territorio",
    "msgSec5Cat2Gr1Qz1": "¿Colabora la empresa colabora con proveedores, entidades financieras y otros aliados comprometidos con el servicio a la comunidad en acciones de desarrollo de los territorios en los que la empresa opera?",

    "msgSec5Cat2Gr2": "Desarrollo de proveedores locales",
    "msgSec5Cat2Gr2Qz1": "Por favor marque la/s respuesta/s que apliquen",
    "msgSec5Cat2Gr2Qz1Ans1": "La empresa favorece la contratación de proveedores locales o regionales.",
    "msgSec5Cat2Gr2Qz1Ans2": "La empresa invierte en el desarrollo de las capacidades de los proveedores locales.",
    "msgSec5Cat2Gr2Qz1Ans3": "La empresa paga en ocasiones a sus entidades proveedoras y subcontratistas locales con anticipación al plazo acordado, de tal forma que facilita financiación a algunas de sus entidades proveedoras.",
    "msgSec5Cat2Gr2Qz1Ans4": "La empresa no trabaja ni colabora con proveedores locales.",

    "msgSec5Cat2Gr3": "Proveedores en comunidades o situaciones desaventajadas",
    "msgSec5Cat2Gr3Qz1": "Por favor marque la/s respuesta/s que apliquen",
    "msgSec5Cat2Gr3Qz1Ans1": "La empresa trabaja con proveedores significativos que se encuentran en comunidades de bajos ingresos.",
    "msgSec5Cat2Gr3Qz1Ans2": "La empresa tiene contratos con proveedores que permiten crear oportunidades laborales para grupos con subempleo crónico. ",
    "msgSec5Cat2Gr3Qz1Ans3": "La empresa subcontrata una parte del proceso productivo a Centros Especiales de Empleo y Centros de Inserción Laboral para favorecer la creación de empleo para personas con discapacidad.",
    "msgSec5Cat2Gr3Qz1Ans4": "La empresa colabora con Centros Especiales de Empleo, actuando como Enclave Laboral para favorecer la transición al mercado ordinario de personas con discapacidad.",
    "msgSec5Cat2Gr3Qz1Ans5": "La empresa no trabaja ni colabora con comunidades o situaciones desaventajadas.",

    "msgSec5Cat2Gr4": "Selección y seguimiento de proveedores",
    "msgSec5Cat2Gr4Qz1": "¿Dispone la empresa de una política formal y escrita que incluya el uso de mecanismos para verificar el cumplimiento por parte de sus proveedores de toda la legislación y reglamentación local, así como de estándares internacionales sobre los Derechos Humanos y prácticas laborales para sus empleados y contratistas?",

    "msgSec5Cat1Gr1": "Relación e implicación con proveedores",
    "msgSec5Cat1Gr1Qz1": "Por favor marque la/s respuesta/s que apliquen",
    "msgSec5Cat1Gr1Qz1Ans1": "La empresa mantiene una relación proactiva y de colaboración con proveedores y subcontratistas, negociando de forma transparente y objetiva las condiciones contratación y pago con todos ellos en base a criterios éticos prefijados que se revisan periódicamente.",
    "msgSec5Cat1Gr1Qz1Ans2": "La empresa incentiva el establecimiento de relaciones duraderas con proveedores.",
    "msgSec5Cat1Gr1Qz1Ans3": "La empresa dispone de herramientas digitales colaborativas para facilitar la relación y gestión con proveedores.",
    "msgSec5Cat1Gr1Qz1Ans4": "La empresa evita tercerizar actividades esenciales para sus operaciones.",
    "msgSec5Cat1Gr1Qz1Ans5": "Ninguna de las anteriores.",

    /** SECTION 6 */
    "msgSec6Cat3Gr1": "Valor medioambiental",
    "msgSec6Cat3Gr1Qz1": "¿Cuentan los productos de la empresa con una certificación ambiental que permite al cliente verificar de manera sencilla que la empresa incorpora consideraciones ambientales durante el proceso de diseño y fabricación de los mismos?",
    "msgSec6Cat3Gr1Qz2": "¿ La empresa ofrece soluciones que permiten abordar de manera directa un problema o reto medioambiental?",

    "msgSec6Cat2Gr1": "Valor social",
    "msgSec6Cat2Gr1Qz1": "¿Ofrece la empresa soluciones que resuelven problemas sociales y que contribuyen al desarrollo social, económico y cultural del ecosistema local en el que opera?",

    "msgSec6Cat2Gr2": "Co-creaciones con la comunidad local",
    "msgSec6Cat2Gr2Qz1": "¿Co-crea la empresa su propuesta de valor y la ofrece de manera conjunta con otros actores del territorio?",

    "msgSec6Cat1Gr1": "Transformación digital",
    "msgSec6Cat1Gr1Qz1": "¿Incluye el <i>offering</i> de la empresa productos que incorporan atributos digitales?",

    /** SECTION 7 */
    "msgSec7Cat3Gr1": "Fase de uso o consumo",
    "msgSec7Cat3Gr1Qz1": "¿Aporta la empresa información sobre los consumos realizados por su producto o servicio durante la fase de uso o prestación (p.e. cantidad de watios consumidos, o huella de carbono por uso)?",

    "msgSec7Cat3Gr2": "Final del ciclo de uso",
    "msgSec7Cat3Gr2Qz1": "¿Dispone la empresa de indicadores que facilitan la trazabilidad de los productos y sus componentes después de cada ciclo de uso, como parte de algún programa de recuperación de productos, piezas o componentes para extender su vida útil, y en el que haga partícipes a sus clientes y usuarios finales?",

    "msgSec7Cat2Gr1": "Comunicación del impacto y atracción de beneficiarios",
    "msgSec7Cat2Gr1Qz1": "Por favor marque la/s respuesta/s que apliquen",
    "msgSec7Cat2Gr1Qz1Ans1": "La empresa implementa prácticas para monitorizar y comunicar el impacto social derivado del uso de sus productos",
    "msgSec7Cat2Gr1Qz1Ans2": "La empresa publica artículos en medios locales y participa en programas y eventos del territorio para dar a conocer el impacto de su propuesta de valor.",
    "msgSec7Cat2Gr1Qz1Ans3": "La empresa participa en asociaciones y plataformas empresariales para aumentar el círculo de beneficiarios de sus soluciones. ",
    "msgSec7Cat2Gr1Qz1Ans4": "Ninguna de las anteriores.",

    "msgSec7Cat2Gr2": "Recursos compartidos con la comunidad local",
    "msgSec7Cat2Gr2Qz1": "¿Comparte la empresa recursos con otras entidades del territorio para optimizar la atención a los clientes y ampliar el número de beneficiarios de la zona en la que opera?",

    "msgSec7Cat1Gr1": "Atracción y fidelización de clientes",
    "msgSec7Cat1Gr1Qz1": "Por favor marque la/s respuesta/s que apliquen",
    "msgSec7Cat1Gr1Qz1Ans1": "La empresa mide la satisfacción de la clientela y tiene estructurado un sistema de fidelización de la clientela.",
    "msgSec7Cat1Gr1Qz1Ans2": "La empresa realiza campañas de comunicación, publicidad y marketing responsables, que no inducen al engaño o al error y que tienen en cuenta aspectos éticos a la hora de ser diseñadas. ",
    "msgSec7Cat1Gr1Qz1Ans3": "La empresa dispone de sellos, certificados o distinciones que acreditan las buenas prácticas en aportar una imagen inclusiva y de normalización de colectivos diversos en sus acciones de marketing y publicidad (marketing inclusivo.",
    "msgSec7Cat1Gr1Qz1Ans4": "En sus campañas de publicidad y marketing la empresa proporciona información de los impactos económicos, ambientales y sociales de su actividad.",
    "msgSec7Cat1Gr1Qz1Ans5": "La empresa ofrece modelos de pago flexibles y condiciones especiales a clientes (p.e., pago por mensualidades, pago a la finalización de la prestación del servicio, etc.).",
    "msgSec7Cat1Gr1Qz1Ans6": "Ninguna de las anteriores.",

    "msgSec7Cat1Gr2": "Atención del cliente",
    "msgSec7Cat1Gr2Qz1": "Por favor marque la/s respuesta/s que apliquen",
    "msgSec7Cat1Gr2Qz1Ans1": "La empresa tiene establecido un sistema para recoger y analizar quejas, reclamaciones y sugerencias de clientes y poner en marcha acciones de mejora derivadas de dichas informaciones.",
    "msgSec7Cat1Gr2Qz1Ans2": "La empresa cuenta con un código de buenas prácticas profesionales para garantizar la honradez y calidad en todas las relaciones con clientes.",
    "msgSec7Cat1Gr2Qz1Ans3": "La empresa presta una atención personalizada y de calidad al cliente, atendiendo a sus necesidades de interacción en cada momento.",
    "msgSec7Cat1Gr2Qz1Ans4": "La empresa ofrece certificados de garantía que exceden los periodos establecidos por ley, y una atención postventa que incluye apoyo y asesoramiento eficientes, así como mantenimiento y reparaciones a un precio razonable una vez superado el periodo de garantía.",
    "msgSec7Cat1Gr2Qz1Ans5": "La empresa está adherida al Sistema Arbitral de Consumo y utiliza mecanismos extrajudiciales (mediación, arbitraje) para la resolución de conflictos cuando lo solicita el cliente.",
    "msgSec7Cat1Gr2Qz1Ans6": "Ninguna de las anteriores. ",

    "msgSec7Cat1Gr3": "Experiencia de usuario",
    "msgSec7Cat1Gr3Qz1": "Por favor marque la/s respuesta/s que apliquen",
    "msgSec7Cat1Gr3Qz1Ans1": "La empresa aplica principios de accesibilidad universal al diseño de productos y servicios, puntos de venta físicos o virtuales y acciones de comunicación, para garantizar su uso cómodo, seguro y lo más autónomo y natural posible por parte de todas las personas incluyendo aquellas con discapacidad y adultos mayores.",
    "msgSec7Cat1Gr3Qz1Ans2": "La empresa aplica medidas para asegurar la seguridad en la prestación de sus servicios y productos durante todo su ciclo de vida.",
    "msgSec7Cat1Gr3Qz1Ans3": "La empresa garantiza el cumplimiento en todo momento de la normativa en materia de Protección de Datos.",
    "msgSec7Cat1Gr3Qz1Ans4": "Ninguna de las anteriores.",

    /** SECTON EIGHT */
    "msgSec8Cat3Gr1": "Acción colectiva por el medioambiente",
    "msgSec8Cat3Gr1Qz1": "Por favor marque la/s respuesta/s que apliquen",
    "msgSec8Cat3Gr1Qz1Ans1": "La empresa colabora con grupos de interés medioambientales, y firma acuerdos con organizaciones vinculadas a la defensa y protección de medioambiente.",
    "msgSec8Cat3Gr1Qz1Ans2": "La empresa ha trabajado con otros actores del sector para impulsar el establecimiento de estándares ambientales para la industria.",
    "msgSec8Cat3Gr1Qz1Ans3": "La empresa ofrece públicamente recursos útiles a sus grupos de interés para mejorar su desempeño ambiental.",
    "msgSec8Cat3Gr1Qz1Ans4": "Ninguna de las anteriores.",

    "msgSec8Cat2Gr1": "Impulso del entorno local",
    "msgSec8Cat2Gr1Qz1": "Por favor marque la/s respuesta/s que apliquen",
    "msgSec8Cat2Gr1Qz1Ans1": "La empresa colabora activamente con diferentes Administraciones Públicas para establecer políticas públicas y programas de desarrollo local, regional y sectorial relacionadas con la propuesta de valor de la compañía.",
    "msgSec8Cat2Gr1Qz1Ans2": "La organización participa en alianzas, foros y proyectos de colaboración público-privada para el desarrollo sostenible del territorio en el que opera.",
    "msgSec8Cat2Gr1Qz1Ans3": "La empresa colabora con entidades educativas para facilitar el aprendizaje dual, la incorporación al mercado de trabajo y la investigación académica en el territorio y en el sector.",
    "msgSec8Cat2Gr1Qz1Ans4": "La empresa colabora con ONGs y otras asociaciones para el definición, desarrollo, puesta en marcha o financiación de proyectos sociales y solidarios.",
    "msgSec8Cat2Gr1Qz1Ans5": "Ninguna de las anteriores.",

    "msgSec8Cat2Gr2": "Conocimiento de los grupos de interés del territorio",
    "msgSec8Cat2Gr2Qz1": "Por favor marque la/s respuesta/s que apliquen",
    "msgSec8Cat2Gr2Qz1Ans1": "La empresa participa en foros y redes para identificar entidades con las que colaborar en proyectos sociales o del territorio.",
    "msgSec8Cat2Gr2Qz1Ans2": "La empresa es consciente de las preocupaciones de los vecinos del entorno local a través de eventos sociales y reuniones periódicas con las asociaciones y otros representantes de la comunidad.",
    "msgSec8Cat2Gr2Qz1Ans3": "La empresa incluye en su página web información dirigida a los habitantes y entidades del territorio.",
    "msgSec8Cat2Gr2Qz1Ans4": "El modelo de negocio de la empresa está orientado a la comunidad local y genera un beneficio específico para actores como organizaciones benéficas asociadas o proveedores desfavorecidos.",
    "msgSec8Cat2Gr2Qz1Ans5": "Ninguna de las anteriores.",

    "msgSec8Cat2Gr3": "Grupos de interés infra-representados",
    "msgSec8Cat2Gr3Qz1": "¿Colabora la empresa con grupos de interés u otras empresas pertenecientes a, o participados por, mujeres, colectivos infra-representados o desfavorecidos?",

    "msgSec8Cat1Gr1": "Establecimiento de relaciones con grupos de interés",
    "msgSec8Cat1Gr1Qz1": "Por favor marque la/s respuesta/s que apliquen",
    "msgSec8Cat1Gr1Qz1Ans1": "La empresa mantiene una comunicación fluida con sus stakeholders a través de reuniones periódicas, sesiones de trabajo, encuestas, etc. para detectar sus preferencias y expectativas e identificar oportunidades de colaboración.",
    "msgSec8Cat1Gr1Qz1Ans2": "La empresa lleva a cabo actuaciones concretas para dar respuesta a las expectativas detectadas.",
    "msgSec8Cat1Gr1Qz1Ans3": "La empresa diseña acciones conjuntas con sus stakeholders en materia de RSC.",
    "msgSec8Cat1Gr1Qz1Ans4": "Ninguna de las anteriores.",

    "msgSec8Cat1Gr2": "Cooperación y alianzas sectoriales",
    "msgSec8Cat1Gr2Qz1": "Por favor marque la/s respuesta/s que apliquen",
    "msgSec8Cat1Gr2Qz1Ans1": "La empresa dispone de políticas de alianzas de cooperación y colaboración con otras empresas del sector.",
    "msgSec8Cat1Gr2Qz1Ans2": "La empresa participa de manera estable en asociaciones y foros para el fortalecimiento de relaciones y el desarrollo conjunto de proyectos sobre intereses, necesidades y problemáticas comunes.",
    "msgSec8Cat1Gr2Qz1Ans3": "La empresa asume prácticas de competencia leal y justa, evitando la utilización de información indebida y la difusión de información tendenciosa sobre la competencia.",
    "msgSec8Cat1Gr2Qz1Ans4": "La empresa busca alternativas de arbitraje y mediación como vía para resolver posibles conflictos con sus grupos de interés",
    "msgSec8Cat1Gr2Qz1Ans5": "Ninguna de las anteriores.",

    /** SECTION NINE */
    "msgSec9Cat3Gr1": "Traslado de productos",
    "msgSec9Cat3Gr1Qz1": "Por favor marque la/s respuesta/s que apliquen",
    "msgSec9Cat3Gr1Qz1Ans1": "La empresa ha implantado medidas que cumplen con toda la legislación aplicable con respecto a los envases y embalajes que utiliza en la comercialización de sus productos: envases retornables, cambios de formato, o sustitución de materiales en packaging y embalajes.",
    "msgSec9Cat3Gr1Qz1Ans2": "La empresa utiliza envases y/o embalajes cuyos materiales de fabricación están certificados y cumplen con estándares independientes relacionados con el impacto ambiental.",
    "msgSec9Cat3Gr1Qz1Ans3": "La empresa ha aplicado una política de reducción los residuos relacionados con los envases y/o embajajes.",
    "msgSec9Cat3Gr1Qz1Ans4": "La empresa utiliza envases y/o embalajes reciclables e incluyen instrucciones para reciclarlos correctamente. ",
    "msgSec9Cat3Gr1Qz1Ans5": "Ninguna de las anteriores.",

    "msgSec9Cat3Gr2": "Transporte sostenible",
    "msgSec9Cat3Gr2Qz1": "¿Fomenta la empresa la optimización de sus rutas comerciales y el uso de vehículos eléctricos o de bajas emisiones en la distribución de sus productos?",

    "msgSec9Cat3Gr3": "Digitalización de las interfaces de relación",
    "msgSec9Cat3Gr3Qz1": "¿Invierte la empresa en virtualizar la distribución, entrega y consumo de sus productos y servicios?",

    "msgSec9Cat2Gr1": "Mejora de infraestructuras",
    "msgSec9Cat2Gr1Qz1": "¿Contribuye la empresa a mejorar las infraestructuras del territorio en el que entrega sus productos o servicios?",

    "msgSec9Cat1Gr1": "Accesibilidad",
    "msgSec9Cat1Gr1Qz1": "¿Dispone la empresa de políticas y sistemas para garantizar la accesibilidad para personas con discapacidad tanto de los canales de relación con sus clientes como de sus puntos de compra o espacios de publicidad físicos?",

    "msgSec9Cat1Gr2": "Salvaguarda de los derechos y condiciones laborales",
    "msgSec9Cat1Gr2Qz1": "¿Mantiene la empresa una relación laboral respetuosa, segura y digna con el personal de reparto?",

    /** END SECTIONS */
    "msgComment": "Comentario",
    "msgClickImage": "Haz click sobre la imagen para expandir RSContract CANVAS",

    "msgLow": "Baja",
    "msgMedium": "Media",
    "msgHight": "Alta",

    "msgCommentHolder": "Escribe aquí cualquier comentario destacado sobre la aplicación de este habilitador en la empresa, pertinencia, medidas emprendidas, otros habilitadores relacionados, etc.",

    "msgEvalBlock": "Diagnostico RSContract por bloque de negocio",
    "msgEvalDimen": "Diagnostico RSContract por dimensión de análisis de triple impacto",

    "msgFooter": "Proyecto financiado por ACCIÓ (Agencia para la Competitividad de la empresa de la Generalitat de Cataluña). Iniciatives de reforç de la competitivitat. Num. Expedient: IRCR19-1-0035",

    "actionStart": "EMPEZAR",
    "actionContinue": "CONTINUAR",
    "actionDoDiagnost": "REALIZAR DIAGNÓSTICO",
    "actionReset": "REINICIAR CUESTIONARIO",
    "actionBack": "VOLVER AL PANEL PRINCIPAL",
    "actionSendResults": "ENVIAR RESULTADOS",
    "actionLoadResults": "CARGAR RESULTADOS",


    "actionYes": "Sí",
    "actionNo": "No",

    "msgIdHolder": "Escribe aquí el nombre de tu empresa o entidad",
    "msgSuccessMail": "Resultados enviados correctamente",
    "errorMail": "Ocurrio un problema inesperado al envíar sus resultados",

    "msgJsondHolder": "Introduce aquí el JSON de tu empresa o entidad",
    "msgSuccessLoad": "Resultados cargados correctamente",
    "errorLoad": "Ocurrio un problema inesperado al cargar sus resultados",

};

export default es;