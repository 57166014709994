import { mdiChevronDown, mdiChevronLeft, mdiChevronRight, mdiChevronUp, mdiComment, mdiChartTree } from '@mdi/js';
import Icon from '@mdi/react';
import React, { Fragment, useEffect, useState } from 'react';
import { useContext } from 'react';
import { useHistory, useLocation } from 'react-router';
import { sectionData } from '../../constants/model';
import { LanguageContext } from '../providers/LanguageProvider';
import { Button, Card, Container, ContainerFlex, TextLocalized } from '../styled/Styled';
import { getQuiz, updateAnswer, updateGroup } from '../util/QuizUitl';

const EmptyAnswer = ({ item }) => {
    const [answers, setAnswers] = useState([])

    useEffect(() => {
        setAnswers([...item.answers]);
    }, [item.answers])
    return <Container width="12" ph="8" pv="16" fontColor="color-black">
        {
            answers.map(answer => <ContainerFlex key={answer.id} width="12" align="center">
                <Container width="wrap" ph="8" children={<TextLocalized children={answer.title} />} />
            </ContainerFlex>)
        }
    </Container>
}

const SingleAnswer = ({ item }) => {
    const [answers, setAnswers] = useState([])

    useEffect(() => {
        setAnswers([...item.answers]);
    }, [item.answers])

    const onChange = (e) => {
        let answersAux = answers.map(answer => {
            answer.selected = parseInt(e.target.value) === answer.id
            return answer;
        })

        let quizAux = { ...item };
        quizAux.answers = answersAux;

        updateAnswer(quizAux);
        setAnswers(answersAux)
    }

    return <Container width="12" ph="8" pv="16" fontColor="color-black">
        {
            answers.map(answer => <ContainerFlex key={answer.id} width="12" mv="8" align="center">
                <input type="radio"
                    checked={answer.selected}
                    onChange={onChange}
                    value={answer.id} name={item.id} />
                <Container width="wrap" ph="8" children={<TextLocalized children={answer.title} />} />
            </ContainerFlex>)
        }
    </Container>
}

const MultipleAnswer = ({ item }) => {
    const [answers, setAnswers] = useState([])

    useEffect(() => {
        setAnswers([...item.answers]);
    }, [item.answers])

    const onChange = (e) => {
        let answersAux = answers.map(answer => {
            if (parseInt(e.target.value) === answer.id)
                answer.selected = !answer.selected;
            return answer;
        })

        let quizAux = { ...item };
        quizAux.answers = answersAux;

        updateAnswer(quizAux);
        setAnswers(answersAux)
    }

    return <Container width="12" ph="8" pv="16" fontColor="color-black">
        {
            answers.map(answer => <ContainerFlex key={answer.id} width="12" mv="8" align="center">
                <input type="checkbox"
                    checked={answer.selected}
                    onChange={onChange}
                    value={answer.id} name={item.id} />
                <Container width="wrap" ph="8" children={<TextLocalized children={answer.title} />} />
            </ContainerFlex>)
        }
    </Container>
}

const QuizItem = ({ item, color }) => {

    return <Card width="12" radius="10px" mt="16"
        border="solid" borderColor={"color-black"} borderWidth="1px" color={"color-white"}>
        <Card px="8" radius="0" radiustl="10px" radiustr="10px" color={color} width="12" fontSize="16px" fontWeight="300" fontColor="color-white" children={<TextLocalized children={item.title} />} />
        {
            item.type_id === 1 ? <SingleAnswer item={item} /> : item.type_id === 2 ? <MultipleAnswer item={item} /> : <EmptyAnswer item={item} />
        }
    </Card>
}

const CategoryGroup = ({ item, color }) => {

    const [group, setGroup] = useState({ ...item });
    const [show, setShow] = useState(false);

    const languageText = useContext(LanguageContext);

    const changeLevel = (level, e) => {
        e.stopPropagation();
        
        let auxLevel = { ...group };
        auxLevel.level = level;
        updateGroup(auxLevel);
        setGroup(auxLevel);
    }

    const changeInfo = (e) => {
        let auxInfo = { ...group };
        auxInfo.info = e.target.value;
        updateGroup(auxInfo);
        setGroup(auxInfo);
    }

    const getLevelColor = () => group.level === 1 ? "color-red" : group.level === 2 ? "color-yellow" : "color-green";
    const getLevelText = () => group.level === 1 ? "msgLow" : group.level === 2 ? "msgMedium" : "msgHight";

    return <Card shadowOn={true} width="12" px="16" mt="16" radius="10px" color="color-white"
        border="solid" borderWidth="2px" borderColor={color}>
        <Button onClick={() => setShow(!show)} flex={true} width="12" align="center">
            <Container width="wrap" fontSize="16px" fontWeight="300" fontColor="color-black" children={<TextLocalized children={group.title} />} />
            {!show && group.info !== "" ? <Icon path={mdiComment} size={1} /> : null}
            {
                !show && group.level ? <Card mh="8" pv="4" ph="8" color={getLevelColor()} fontColor="color-white" fontSize="12px" children={<TextLocalized children={getLevelText()} />} />
                    : show ? <ContainerFlex>
                        <Button onClick={(e) => changeLevel(1, e)} mh="8" pv="4" ph="8" color="color-red" opacity={(group.level ?? 0) === 1 ? 1 : 0.5} fontSize="12px" fontColor="color-white" children={<TextLocalized children="msgLow" />} />
                        <Button onClick={(e) => changeLevel(2, e)} mh="8" pv="4" ph="8" color="color-yellow" opacity={(group.level ?? 0) === 2 ? 1 : 0.5} fontSize="12px" fontColor="color-white" children={<TextLocalized children="msgMedium" />} />
                        <Button onClick={(e) => changeLevel(3, e)} mh="8" pv="4" ph="8" color="color-green" opacity={(group.level ?? 0) === 3 ? 1 : 0.5} fontSize="12px" fontColor="color-white" children={<TextLocalized children="msgHight" />} />
                    </ContainerFlex>
                        : null
            }
            <Container maxheight="24px" children={<Icon size={1} path={show ? mdiChevronUp : mdiChevronDown} />} />
        </Button>

        {show ? <Fragment>
            {item.quizs.map((quiz) => <QuizItem key={quiz.id} item={quiz} color={color} />)}
            <Card width="12" radius="10px" mt="16"
                border="solid" borderColor={"color-black"} borderWidth="1px" color={"color-white"}>
                <Card px="8" radius="0" radiustl="10px" radiustr="10px" color={color} width="12" fontSize="16px" fontWeight="300" fontColor="color-white" children={<TextLocalized children="msgComment" />} />
                <Container px="8" children={<input onChange={changeInfo} value={group.info} placeholder={languageText.dictionary["msgCommentHolder"]} type="text" />} />
            </Card>
        </Fragment> : null}
    </Card>
}

const CategoryItem = ({ item }) => {
    const getColor = () => item.id === 1 ? "color-economic" : item.id === 2 ? "color-social" : "color-ecolo";

    return <Container width="12">
        <ContainerFlex width="12" align="center" mv="32" fontSize="18px" fontWeight="300" fontColor="color-black">
            <TextLocalized children={item.title} />
            <Card mh="8" minwidth="18px" minheight="18px" color={getColor()} radius="50%" />
        </ContainerFlex>
        {item.groups.map((group) => <CategoryGroup key={group.id} item={group} color={getColor()} />)}
    </Container>
}

const SectionView = () => {
    const [item, setItem] = useState();
    const [index, setIndex] = useState(0);

    let history = useHistory();
    let location = useLocation();

    useEffect(() => {
        let localQuiz = getQuiz();
        let section = localQuiz.filter(section => section.id === location.state.item.id);

        setItem(section[0]);
        setIndex(localQuiz.findIndex(value => value.id === section[0].id))
    }, [location.state.item.id])

    const nextBlock = () => {
        let localQuiz = getQuiz();

        setItem(localQuiz[index + 1]);
        setIndex(index + 1);

        window.scrollTo({ top: 0, behavior: 'smooth' })
    }

    const prevBlock = () => {
        let localQuiz = getQuiz();
        setItem(localQuiz[index - 1]);
        setIndex(index - 1);
        window.scrollTo({ top: 0, behavior: 'smooth' })
    }



    return item ? (
        <ContainerFlex width="12" justify="center" minheight="calc(100vh - 92px)">
            <ContainerFlex width="12" lg="8" xl="8" justify="center" >
                <Container width="12" textAlign="center" fontWeight="bold" fontSize="28px" fontColor="color-gray" children={<TextLocalized children="homeTitle" />} />
                <Card mv="16" minwidth="200px" height="5px" color="color-blue" radius="2px" />

                <ContainerFlex width="12" fontColor="color-black" align="center" pt="32">
                    <Button onClick={() => history.goBack()} flex={true} px="8" justify="center" align="center" color="color-blue" hoverColor="color-blue-dark" fontColor="color-white" radius="50%" children={<Icon path={mdiChevronLeft} size={1} />} />
                    <Button onClick={() => history.goBack()} ph="16" fontWeight="bold" children={<TextLocalized children="actionBack" />} />
                </ContainerFlex>

                <Card flex={true} shadowOn={true} color="color-white" width="12" mt="32" align="stretch" radius="10px">
                    <Container width="wrap" pv="24" ph="8">
                        <Container width="12" ph="8" pv="8" fontColor="color-gray" children={<TextLocalized children="msgArea" />} />
                        <Container width="12" ph="8" fontWeight="bold" children={<TextLocalized children={item.title} />} />
                        <Container width="12" ph="8" pv="16" children={<TextLocalized children={item.description} />} />
                    </Container>
                    <Card radiustl="0" radiusbl="0" radius="10px" minwidth="220px" minheight="120px" image={item.image} />
                </Card>

                {item.categories.map((cat) => <CategoryItem key={cat.id} item={cat} />)}

                <ContainerFlex width="12" justify="space-around">
                    {
                        index > 0 ?
                            <ContainerFlex fontColor="color-black" align="center" pv="32">
                                <Button onClick={() => prevBlock()} flex={true} justify="center" align="center" color="color-blue" hoverColor="color-blue-dark" fontColor="color-white" radius="50%" children={<Icon path={mdiChevronLeft} size={2} />} />
                                <Button onClick={() => prevBlock()} ph="16" fontWeight="bold" children={<TextLocalized children={sectionData[index - 1].title} />} />
                            </ContainerFlex>
                            : null
                    }
                    {
                        index < sectionData.length - 1 ?
                            <ContainerFlex fontColor="color-black" align="center" pv="32">
                                <Button onClick={() => nextBlock()} ph="16" fontWeight="bold" children={<TextLocalized children={sectionData[index + 1].title} />} />
                                <Button onClick={() => nextBlock()} flex={true} justify="center" align="center" color="color-blue" hoverColor="color-blue-dark" fontColor="color-white" radius="50%" children={<Icon path={mdiChevronRight} size={2} />} />
                            </ContainerFlex>
                            :
                            <ContainerFlex fontColor="color-black" align="center" pv="32">
                                <Button onClick={() => history.push('/results')} ph="16" fontWeight="bold" children={<TextLocalized children={"actionDoDiagnost"} />} />
                                <Button onClick={() => history.push('/results')} flex={true} px="12" justify="center" align="center" color="color-blue" hoverColor="color-blue-dark" fontColor="color-white" radius="50%" children={<Icon path={mdiChartTree} size={1} />} />
                            </ContainerFlex>
                    }
                </ContainerFlex>
            </ContainerFlex>

        </ContainerFlex>
    ) : null;
};

export default SectionView;