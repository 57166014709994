import React from 'react';
import { Button, Container, ContainerFlex, Card, TextLocalized } from '../styled/Styled';

import logo_accio from '../../images/logo_accio.png';
import logo_cenfim from '../../images/logo_cenfim.png';

const Footer = () => {
    return (
        <ContainerFlex width="12" justify="center" align="center" pv="48" fontSize="15px" fontColor="color-gray">
            <ContainerFlex width="12" lg="8" align="center" justify="center" >
                <Container width="wrap">
                    <TextLocalized children="msgFooter" />
                    <ContainerFlex width="12" justify="flex-start">
                        <Card mv="16" width="12" height="2px" color="color-blue" radius="2px" />

                        <Button shadowOn={false} fontHoverColor="color-black" children={<TextLocalized children="msgCopy" />} />
                        <Button shadowOn={false} fontHoverColor="color-black" mh="16" children={<TextLocalized children="msgPrivacy" />} />
                        <Button shadowOn={false} fontHoverColor="color-black" children={<TextLocalized children="msgContact" />} />
                    </ContainerFlex>
                </Container>
                <Container px="16" children={<img alt="accio" height="120px" src={logo_accio} />} />
                <Container px="16" children={<img alt="cenfim" height="100px" src={logo_cenfim} />} />
            </ContainerFlex>

        </ContainerFlex>
    );
};

export default Footer;