import React, { useContext, useEffect } from 'react';
import { Button, Container, ContainerFlex } from '../styled/Styled';
import logo from '../../images/logo.png';
import { LanguageContext, languageOptions } from '../providers/LanguageProvider';

const Header = () => {

    const { language, setLanguage } = useContext(LanguageContext);

    useEffect(() => {
        console.log(language)
    }, [language])

    return (
        <ContainerFlex width="12" justify="space-between" align="center" ph="32" pv="26">
            <Container width="wrap" />
            <img alt="logo" src={logo} height="40px" />
            <ContainerFlex width="wrap" justify="flex-end" fontColor="color-gray" fontSize="13px">
                <Button onClick={() => setLanguage(languageOptions[0])} mh="8" opacity={language.id === "es" ? 1 : 0.5} fontWeight="bold" shadowOn={false} fontColor="color-blue" children={"ES"} />
                <Button onClick={() => setLanguage(languageOptions[1])} mh="8" opacity={language.id === "cat" ? 1 : 0.5} fontWeight="bold" shadowOn={false} fontColor="color-blue" children={"CAT"} />
            </ContainerFlex>
        </ContainerFlex>
    );
};

export default Header;