import './css/normalize.css';
import './css/bootstrap-grid.css';
import './css/App.css';
import { LanguageProvider } from './components/providers/LanguageProvider';
import { UserProvider } from './components/providers/UserProvider';
import { Fragment } from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import HomeView from './components/views/HomeView';
import { Container } from './components/styled/Styled';
import Header from './components/views/Header';
import Footer from './components/views/Footer';
import SectionView from './components/views/SectionView';
import EvalView from './components/views/EvalView';
import LoadJsonView from './components/views/LoadJsonView';

const SwitchView = () => {

  return <Fragment>
    <Container width="12" minheight="100vh">
      <Router basename="/">
        <Header />
        <Switch>
          <Route exact={true} path="/" component={HomeView} />
          <Route exact={true} path="/section" component={SectionView} />
          <Route exact={true} path="/load" component={LoadJsonView} />
          <Route exact={true} path="/results" component={EvalView} />
        </Switch>
        <Footer />
      </Router>
    </Container>
  </Fragment>
}

const App = () => <LanguageProvider children={<UserProvider children={<SwitchView />} />} />

export default App;