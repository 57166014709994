import React, { useReducer } from 'react';
import { sectionData } from '../../constants/model';
import { notificationDefault } from '../util/NotificationUtil';
import { getQuiz } from '../util/QuizUitl';

const UserContext = React.createContext();
const UserConsumer = UserContext.Consumer;

export const UserData = {
    quiz: "SAVE_RSC_QUIZ",
    lang: "SAVE_RSC_LANG",
}

const initState = {
    quiz: getQuiz(),
    notification: { ...notificationDefault },
}

const UserAction = {
    showNoti: "SHOW_NOTI",
    quizReset: "QUIZ_RESET"
}

const reduce = (state, callback) => {
    switch (callback.action) {
        case UserAction.showNoti:
            return { ...state, notification: callback.data }
        case UserAction.quizReset:
            return { ...state, quiz: { ...sectionData } }
        default:
            return state
    }
}

const UserProvider = (props) => {
    const [state, dispatch] = useReducer(reduce, initState);
    let value = { state, dispatch };
    return <UserContext.Provider value={value} children={props.children} />;
}

export { UserContext, UserProvider, UserConsumer, UserAction }