import { sectionData } from "../../constants/model";
import { UserData } from "../providers/UserProvider"

export const resetQuiz = () => {
    localStorage.setItem(UserData.quiz, JSON.stringify([...sectionData]))
    return [...sectionData];
}

export const getQuiz = () => {
    let quizStirng = localStorage.getItem(UserData.quiz);
    if (!quizStirng)
        localStorage.setItem(UserData.quiz, JSON.stringify([...sectionData]))
    return quizStirng ? JSON.parse(quizStirng) : sectionData;
}

export const updateAnswer = (item) => {
    let localQuiz = getQuiz();
    localQuiz = localQuiz.map(section => {
        section.categories = section.categories.map(category => {
            category.groups = category.groups.map(group => {
                group.quizs = group.quizs.map(quiz => {
                    return quiz.id === item.id ? item : quiz;
                })
                return group;
            })
            return category;
        })
        return section;
    })
    saveQuiz(localQuiz);
}

export const updateGroup = (item) => {
    let localQuiz = getQuiz();
    localQuiz = localQuiz.map(section => {
        section.categories = section.categories.map(category => {
            category.groups = category.groups.map(group => {
                if (group.id === item.id) {
                    group.info = item.info;
                    group.level = item.level;
                }
                return group;
            })
            return category;
        })
        return section;
    })
    saveQuiz(localQuiz);
}

export const saveQuiz = (item) => {
    localStorage.setItem(UserData.quiz, JSON.stringify(item));
}