import React, { useState, useContext } from 'react';

import es from '../../constants/es';
import cat from '../../constants/cat';
import { UserData } from './UserProvider';

export const dictionaryList = {
  es,
  cat
};

export const languageOptions = [
  { id: 'es', text: 'Spanish' },
  { id: 'cat', text: 'Catalan' },
];

export const LanguageContext = React.createContext({
  language: localStorage.getItem(UserData.lang) === languageOptions[0].id ? languageOptions[0] : languageOptions[1],
  dictionary: localStorage.getItem(UserData.lang) === languageOptions[0].id ? dictionaryList[languageOptions[0].id] : dictionaryList[languageOptions[1].id]
});

// it provides the language context to app
export function LanguageProvider(props) {
  const languageContext = useContext(LanguageContext);
  const [language, setLanguage] = useState(languageContext.language);
  const [dictionary, setDictionary] = useState(languageContext.dictionary);

  const provider = {
    language,
    dictionary,
    setLanguage: (selectedLanguage) => {
      setLanguage(selectedLanguage); // it will update the language in state
      setDictionary(dictionaryList[selectedLanguage.id]);

      //Save lang local
      localStorage.setItem(UserData.lang, selectedLanguage.id);
    }
  };

  return <LanguageContext.Provider value={provider} children={props.children} />;
};