
import sec_one from '../images/sec_one.jpeg';
import sec_two from '../images/sec_two.jpeg';
import sec_three from '../images/sec_three.jpeg';
import sec_four from '../images/sec_four.jpeg';
import sec_five from '../images/sec_five.jpeg';
import sec_six from '../images/sec_six.jpeg';
import sec_seven from '../images/sec_seven.jpeg';
import sec_eight from '../images/sec_eight.jpeg';
import sec_nine from '../images/sec_nine.jpeg';


const sectionOne = {
    id: 1,
    title: "msgSection1",
    description_short: "msgSection1DescShort",
    description: "msgSection1Desc",
    image: sec_one,
    group_id: 1,
    categories: [
        {
            id: 1,
            title: "msgCat1",
            groups: [
                {
                    id: 7,
                    title: "msgSec1Cat1Gr1",
                    level: null,
                    info: "",
                    quizs: [
                        {
                            id: 8,
                            type_id: 1,
                            title: "msgSec1Cat1Gr1Qz1",
                            answers: [
                                {
                                    id: 1,
                                    title: "actionYes",
                                    weight: 1,
                                    selected: false,
                                },
                                {
                                    id: 2,
                                    title: "actionNo",
                                    weight: 0,
                                    selected: false,
                                }
                            ]
                        },
                        {
                            id: 9,
                            type_id: 1,
                            title: "msgSec1Cat1Gr1Qz2",
                            answers: [
                                {
                                    id: 1,
                                    title: "actionYes",
                                    weight: 1,
                                    selected: false,
                                },
                                {
                                    id: 2,
                                    title: "actionNo",
                                    weight: 0,
                                    selected: false,
                                }
                            ]
                        },
                    ]
                },
                {
                    id: 8,
                    title: "msgSec1Cat1Gr2",
                    level: null,
                    info: "",
                    quizs: [
                        {
                            id: 10,
                            type_id: 1,
                            title: "msgSec1Cat1Gr2Qz1",
                            answers: [
                                {
                                    id: 1,
                                    title: "actionYes",
                                    weight: 1,
                                    selected: false,
                                },
                                {
                                    id: 2,
                                    title: "actionNo",
                                    weight: 0,
                                    selected: false,
                                }
                            ]
                        },
                    ]
                },
                {
                    id: 9,
                    title: "msgSec1Cat1Gr3",
                    level: null,
                    info: "",
                    quizs: [
                        {
                            id: 11,
                            type_id: 1,
                            title: "msgSec1Cat1Gr3Qz1",
                            answers: [
                                {
                                    id: 1,
                                    title: "actionYes",
                                    weight: 1,
                                    selected: false,
                                },
                                {
                                    id: 2,
                                    title: "actionNo",
                                    weight: 0,
                                    selected: false,
                                }
                            ]
                        },
                        {
                            id: 12,
                            type_id: 1,
                            title: "msgSec1Cat1Gr3Qz2",
                            answers: [
                                {
                                    id: 1,
                                    title: "actionYes",
                                    weight: 1,
                                    selected: false,
                                },
                                {
                                    id: 2,
                                    title: "actionNo",
                                    weight: 0,
                                    selected: false,
                                }
                            ]
                        },
                    ]
                },
                {
                    id: 10,
                    title: "msgSec1Cat1Gr4",
                    level: null,
                    info: "",
                    quizs: [
                        {
                            id: 13,
                            type_id: 1,
                            title: "msgSec1Cat1Gr4Qz1",
                            answers: [
                                {
                                    id: 1,
                                    title: "actionYes",
                                    weight: 1,
                                    selected: false,
                                },
                                {
                                    id: 2,
                                    title: "actionNo",
                                    weight: 0,
                                    selected: false,
                                }
                            ]
                        },
                    ]
                },
                {
                    id: 11,
                    title: "msgSec1Cat1Gr5",
                    level: null,
                    info: "",
                    quizs: [
                        {
                            id: 14,
                            type_id: 1,
                            title: "msgSec1Cat1Gr5Qz1",
                            answers: [
                                {
                                    id: 1,
                                    title: "actionYes",
                                    weight: 1,
                                    selected: false,
                                },
                                {
                                    id: 2,
                                    title: "actionNo",
                                    weight: 0,
                                    selected: false,
                                }
                            ]
                        },
                        {
                            id: 15,
                            type_id: 1,
                            title: "msgSec1Cat1Gr5Qz2",
                            answers: [
                                {
                                    id: 1,
                                    title: "actionYes",
                                    weight: 1,
                                    selected: false,
                                },
                                {
                                    id: 2,
                                    title: "actionNo",
                                    weight: 0,
                                    selected: false,
                                }
                            ]
                        },
                    ]
                },
            ]
        },
        {
            id: 2,
            title: "msgCat2",
            groups: [
                {
                    id: 3,
                    title: "msgSec1Cat2Gr1",
                    level: null,
                    info: "",
                    quizs: [
                        {
                            id: 3,
                            type_id: 1,
                            title: "msgSec1Cat2Gr1Qz1",
                            answers: [
                                {
                                    id: 1,
                                    title: "actionYes",
                                    weight: 1,
                                    selected: false,
                                },
                                {
                                    id: 2,
                                    title: "actionNo",
                                    weight: 0,
                                    selected: false,
                                }
                            ]
                        },
                        {
                            id: 4,
                            type_id: 1,
                            title: "msgSec1Cat2Gr1Qz2",
                            answers: [
                                {
                                    id: 1,
                                    title: "actionYes",
                                    weight: 1,
                                    selected: false,
                                },
                                {
                                    id: 2,
                                    title: "actionNo",
                                    weight: 0,
                                    selected: false,
                                }
                            ]
                        },
                    ]
                },
                {
                    id: 4,
                    title: "msgSec1Cat2Gr2",
                    level: null,
                    info: "",
                    quizs: [
                        {
                            id: 5,
                            type_id: 1,
                            title: "msgSec1Cat2Gr2Qz1",
                            answers: [
                                {
                                    id: 1,
                                    title: "actionYes",
                                    weight: 1,
                                    selected: false,
                                },
                                {
                                    id: 2,
                                    title: "actionNo",
                                    weight: 0,
                                    selected: false,
                                }
                            ]
                        },
                    ]
                },
                {
                    id: 5,
                    title: "msgSec1Cat2Gr3",
                    level: null,
                    info: "",
                    quizs: [
                        {
                            id: 6,
                            type_id: 1,
                            title: "msgSec1Cat2Gr3Qz1",
                            answers: [
                                {
                                    id: 1,
                                    title: "actionYes",
                                    weight: 1,
                                    selected: false,
                                },
                                {
                                    id: 2,
                                    title: "actionNo",
                                    weight: 0,
                                    selected: false,
                                }
                            ]
                        },
                    ]
                },
                {
                    id: 6,
                    title: "msgSec1Cat2Gr4",
                    level: null,
                    info: "",
                    quizs: [
                        {
                            id: 7,
                            type_id: 1,
                            title: "msgSec1Cat2Gr4Qz1",
                            answers: [
                                {
                                    id: 1,
                                    title: "actionYes",
                                    weight: 1,
                                    selected: false,
                                },
                                {
                                    id: 2,
                                    title: "actionNo",
                                    weight: 0,
                                    selected: false,
                                }
                            ]
                        },
                    ]
                },
            ]
        },
        {
            id: 3,
            title: "msgCat3",
            groups: [
                {
                    id: 1,
                    title: "msgSec1Cat3Gr1",
                    level: null,
                    info: "",
                    quizs: [
                        {
                            id: 1,
                            type_id: 1,
                            title: "msgSec1Cat3Gr1Qz1",
                            answers: [
                                {
                                    id: 1,
                                    title: "actionYes",
                                    weight: 1,
                                    selected: false,
                                },
                                {
                                    id: 2,
                                    title: "actionNo",
                                    weight: 0,
                                    selected: false,
                                }
                            ]
                        },
                    ]
                },
                {
                    id: 2,
                    title: "msgSec1Cat3Gr2",
                    level: null,
                    info: "",
                    quizs: [
                        {
                            id: 2,
                            type_id: 1,
                            title: "msgSec1Cat3Gr2Qz1",
                            answers: [
                                {
                                    id: 1,
                                    title: "actionYes",
                                    weight: 1,
                                    selected: false,
                                },
                                {
                                    id: 2,
                                    title: "actionNo",
                                    weight: 0,
                                    selected: false,
                                }
                            ]
                        },
                    ]
                }
            ]
        },
    ],
}

const sectionTwo = {
    id: 2,
    title: "msgSection2",
    description_short: "msgSection2DescShort",
    description: "msgSection2Desc",
    image: sec_two,
    group_id: 1,
    categories: [
        {
            id: 1,
            title: "msgCat1",
            groups: [
                {
                    id: 20,
                    title: "msgSec2Cat1Gr1",
                    level: null,
                    info: "",
                    quizs: [
                        {
                            id: 24,
                            type_id: 1,
                            title: "msgSec2Cat1Gr1Qz1",
                            answers: [
                                {
                                    id: 1,
                                    title: "actionYes",
                                    weight: 1,
                                    selected: false,
                                },
                                {
                                    id: 2,
                                    title: "actionNo",
                                    weight: 0,
                                    selected: false,
                                }
                            ]
                        },
                        {
                            id: 25,
                            type_id: 1,
                            title: "msgSec2Cat1Gr1Qz2",
                            answers: [
                                {
                                    id: 1,
                                    title: "actionYes",
                                    weight: 1,
                                    selected: false,
                                },
                                {
                                    id: 2,
                                    title: "actionNo",
                                    weight: 0,
                                    selected: false,
                                }
                            ]
                        },
                    ]
                },
                {
                    id: 21,
                    title: "msgSec2Cat1Gr2",
                    level: null,
                    info: "",
                    quizs: [
                        {
                            id: 26,
                            type_id: 1,
                            title: "msgSec2Cat1Gr2Qz1",
                            answers: [
                                {
                                    id: 1,
                                    title: "msgSec2Cat1Gr2Qz1Ans1",
                                    weight: 0,
                                    selected: false,
                                },
                                {
                                    id: 2,
                                    title: "msgSec2Cat1Gr2Qz1Ans2",
                                    weight: 0.10,
                                    selected: false,
                                },
                                {
                                    id: 3,
                                    title: "msgSec2Cat1Gr2Qz1Ans3",
                                    weight: 0.25,
                                    selected: false,
                                },
                                {
                                    id: 4,
                                    title: "msgSec2Cat1Gr2Qz1Ans4",
                                    weight: 0.50,
                                    selected: false,
                                },
                                {
                                    id: 5,
                                    title: "msgSec2Cat1Gr2Qz1Ans5",
                                    weight: 1,
                                    selected: false,
                                }
                            ]
                        },
                    ]
                },
                {
                    id: 22,
                    title: "msgSec2Cat1Gr3",
                    level: null,
                    info: "",
                    quizs: [
                        {
                            id: 27,
                            type_id: 2,
                            title: "msgSec2Cat1Gr3Qz1",
                            answers: [
                                {
                                    id: 1,
                                    title: "msgSec2Cat1Gr3Qz1Ans1",
                                    weight: 0.333,
                                    selected: false,
                                },
                                {
                                    id: 2,
                                    title: "msgSec2Cat1Gr3Qz1Ans2",
                                    weight: 0.333,
                                    selected: false,
                                },
                                {
                                    id: 3,
                                    title: "msgSec2Cat1Gr3Qz1Ans3",
                                    weight: 0.333,
                                    selected: false,
                                },
                                {
                                    id: 4,
                                    title: "msgSec2Cat1Gr3Qz1Ans4",
                                    weight: 0,
                                    selected: false,
                                },
                            ]
                        },
                    ]
                },
                {
                    id: 23,
                    title: "msgSec2Cat1Gr4",
                    level: null,
                    info: "",
                    quizs: [
                        {
                            id: 28,
                            type_id: 1,
                            title: "msgSec2Cat1Gr4Qz1",
                            answers: [
                                {
                                    id: 1,
                                    title: "msgSec2Cat1Gr4Qz1Ans1",
                                    weight: 0.5,
                                    selected: false,
                                },
                                {
                                    id: 2,
                                    title: "msgSec2Cat1Gr4Qz1Ans2",
                                    weight: 0.15,
                                    selected: false,
                                },
                                {
                                    id: 3,
                                    title: "msgSec2Cat1Gr4Qz1Ans3",
                                    weight: 1,
                                    selected: false,
                                },
                                {
                                    id: 4,
                                    title: "msgSec2Cat1Gr4Qz1Ans4",
                                    weight: 0,
                                    selected: false,
                                },
                            ]
                        },
                    ]
                },
                {
                    id: 24,
                    title: "msgSec2Cat1Gr5",
                    level: null,
                    info: "",
                    quizs: [
                        {
                            id: 29,
                            type_id: 1,
                            title: "msgSec2Cat1Gr5Qz1",
                            answers: [
                                {
                                    id: 1,
                                    title: "msgSec2Cat1Gr5Qz1Ans1",
                                    weight: 0.5,
                                    selected: false,
                                },
                                {
                                    id: 2,
                                    title: "msgSec2Cat1Gr5Qz1Ans2",
                                    weight: 0.15,
                                    selected: false,
                                },
                                {
                                    id: 3,
                                    title: "msgSec2Cat1Gr5Qz1Ans3",
                                    weight: 1,
                                    selected: false,
                                },
                                {
                                    id: 4,
                                    title: "msgSec2Cat1Gr5Qz1Ans4",
                                    weight: 0,
                                    selected: false,
                                },
                                {
                                    id: 5,
                                    title: "msgSec2Cat1Gr5Qz1Ans5",
                                    weight: 0,
                                    selected: false,
                                },
                            ]
                        },
                    ]
                },
            ]
        },
        {
            id: 2,
            title: "msgCat2",
            groups: [
                {
                    id: 15,
                    title: "msgSec2Cat2Gr1",
                    level: null,
                    info: "",
                    quizs: [
                        {
                            id: 19,
                            type_id: 1,
                            title: "msgSec2Cat2Gr1Qz1",
                            answers: [
                                {
                                    id: 1,
                                    title: "actionYes",
                                    weight: 1,
                                    selected: false,
                                },
                                {
                                    id: 2,
                                    title: "actionNo",
                                    weight: 0,
                                    selected: false,
                                }
                            ]
                        },
                    ]
                },
                {
                    id: 16,
                    title: "msgSec2Cat2Gr2",
                    level: null,
                    info: "",
                    quizs: [
                        {
                            id: 20,
                            type_id: 1,
                            title: "msgSec2Cat2Gr2Qz1",
                            answers: [
                                {
                                    id: 1,
                                    title: "actionYes",
                                    weight: 1,
                                    selected: false,
                                },
                                {
                                    id: 2,
                                    title: "actionNo",
                                    weight: 0,
                                    selected: false,
                                }
                            ]
                        },
                    ]
                },
                {
                    id: 17,
                    title: "msgSec2Cat2Gr3",
                    level: null,
                    info: "",
                    quizs: [
                        {
                            id: 21,
                            type_id: 1,
                            title: "msgSec2Cat2Gr3Qz1",
                            answers: [
                                {
                                    id: 1,
                                    title: "actionYes",
                                    weight: 1,
                                    selected: false,
                                },
                                {
                                    id: 2,
                                    title: "actionNo",
                                    weight: 0,
                                    selected: false,
                                }
                            ]
                        },
                    ]
                },
                {
                    id: 18,
                    title: "msgSec2Cat2Gr4",
                    level: null,
                    info: "",
                    quizs: [
                        {
                            id: 22,
                            type_id: 1,
                            title: "msgSec2Cat2Gr4Qz1",
                            answers: [
                                {
                                    id: 1,
                                    title: "actionYes",
                                    weight: 1,
                                    selected: false,
                                },
                                {
                                    id: 2,
                                    title: "actionNo",
                                    weight: 0,
                                    selected: false,
                                }
                            ]
                        },
                    ]
                },
                {
                    id: 19,
                    title: "msgSec2Cat2Gr5",
                    level: null,
                    info: "",
                    quizs: [
                        {
                            id: 23,
                            type_id: 1,
                            title: "msgSec2Cat2Gr5Qz1",
                            answers: [
                                {
                                    id: 1,
                                    title: "actionYes",
                                    weight: 1,
                                    selected: false,
                                },
                                {
                                    id: 2,
                                    title: "actionNo",
                                    weight: 0,
                                    selected: false,
                                }
                            ]
                        },
                    ]
                },
            ]
        },
        {
            id: 3,
            title: "msgCat3",
            groups: [
                {
                    id: 12,
                    title: "msgSec2Cat3Gr1",
                    level: null,
                    info: "",
                    quizs: [
                        {
                            id: 16,
                            type_id: 1,
                            title: "msgSec2Cat3Gr1Qz1",
                            answers: [
                                {
                                    id: 1,
                                    title: "actionYes",
                                    weight: 1,
                                    selected: false,
                                },
                                {
                                    id: 2,
                                    title: "actionNo",
                                    weight: 0,
                                    selected: false,
                                }
                            ]
                        },
                    ]
                },
                {
                    id: 13,
                    title: "msgSec2Cat3Gr2",
                    level: null,
                    info: "",
                    quizs: [
                        {
                            id: 17,
                            type_id: 1,
                            title: "msgSec2Cat3Gr2Qz1",
                            answers: [
                                {
                                    id: 1,
                                    title: "actionYes",
                                    weight: 1,
                                    selected: false,
                                },
                                {
                                    id: 2,
                                    title: "actionNo",
                                    weight: 0,
                                    selected: false,
                                }
                            ]
                        },
                    ]
                },
                {
                    id: 14,
                    title: "msgSec2Cat3Gr3",
                    level: null,
                    info: "",
                    quizs: [
                        {
                            id: 18,
                            type_id: 1,
                            title: "msgSec2Cat3Gr3Qz1",
                            answers: [
                                {
                                    id: 1,
                                    title: "actionYes",
                                    weight: 1,
                                    selected: false,
                                },
                                {
                                    id: 2,
                                    title: "actionNo",
                                    weight: 0,
                                    selected: false,
                                }
                            ]
                        },
                    ]
                },
            ]
        },
    ],
}

const sectionThree = {
    id: 3,
    title: "msgSection3",
    description_short: "msgSection3DescShort",
    description: "msgSection3Desc",
    image: sec_three,
    group_id: 1,
    categories: [
        {
            id: 1,
            title: "msgCat1",
            groups: [
                {
                    id: 70,
                    title: "msgSec3Cat1Gr1",
                    level: null,
                    info: "",
                    quizs: [
                        {
                            id: 76,
                            type_id: 3,
                            title: "msgSec3Cat1Gr1Qz1",
                            answers: [
                                {
                                    id: 1,
                                    title: "msgSec3Cat1Gr1Qz1Ans1",
                                    weight: 1,
                                    selected: true,
                                },
                            ]
                        },
                    ]
                },
            ]
        },
        {
            id: 2,
            title: "msgCat2",
            groups: [
                {
                    id: 28,
                    title: "msgSec3Cat2Gr1",
                    level: null,
                    info: "",
                    quizs: [
                        {
                            id: 33,
                            type_id: 2,
                            title: "msgSec3Cat2Gr1Qz1",
                            answers: [
                                {
                                    id: 1,
                                    title: "msgSec3Cat2Gr1Qz1Ans1",
                                    weight: 0.25,
                                    selected: false,
                                },
                                {
                                    id: 2,
                                    title: "msgSec3Cat2Gr1Qz1Ans2",
                                    weight: 0.25,
                                    selected: false,
                                },
                                {
                                    id: 3,
                                    title: "msgSec3Cat2Gr1Qz1Ans3",
                                    weight: 0.25,
                                    selected: false,
                                },
                                {
                                    id: 4,
                                    title: "msgSec3Cat2Gr1Qz1Ans4",
                                    weight: 0.25,
                                    selected: false,
                                },
                                {
                                    id: 5,
                                    title: "msgSec3Cat2Gr1Qz1Ans5",
                                    weight: 0,
                                    selected: false,
                                },
                            ]
                        },
                    ]
                },
                {
                    id: 29,
                    title: "msgSec3Cat2Gr2",
                    level: null,
                    info: "",
                    quizs: [
                        {
                            id: 34,
                            type_id: 1,
                            title: "msgSec3Cat2Gr2Qz1",
                            answers: [
                                {
                                    id: 1,
                                    title: "actionYes",
                                    weight: 1,
                                    selected: false,
                                },
                                {
                                    id: 2,
                                    title: "actionNo",
                                    weight: 0,
                                    selected: false,
                                }
                            ]
                        },
                    ]
                },
                {
                    id: 30,
                    title: "msgSec3Cat2Gr3",
                    level: null,
                    info: "",
                    quizs: [
                        {
                            id: 35,
                            type_id: 1,
                            title: "msgSec3Cat2Gr3Qz1",
                            answers: [
                                {
                                    id: 1,
                                    title: "actionYes",
                                    weight: 1,
                                    selected: false,
                                },
                                {
                                    id: 2,
                                    title: "actionNo",
                                    weight: 0,
                                    selected: false,
                                }
                            ]
                        },
                    ]
                },
            ]
        },
        {
            id: 3,
            title: "msgCat3",
            groups: [
                {
                    id: 25,
                    title: "msgSec3Cat3Gr1",
                    level: null,
                    info: "",
                    quizs: [
                        {
                            id: 30,
                            type_id: 2,
                            title: "msgSec3Cat3Gr1Qz1",
                            answers: [
                                {
                                    id: 1,
                                    title: "msgSec3Cat3Gr1Qz1Ans1",
                                    weight: 0.25,
                                    selected: false,
                                },
                                {
                                    id: 2,
                                    title: "msgSec3Cat3Gr1Qz1Ans2",
                                    weight: 0.25,
                                    selected: false,
                                },
                                {
                                    id: 3,
                                    title: "msgSec3Cat3Gr1Qz1Ans3",
                                    weight: 0.25,
                                    selected: false,
                                },
                                {
                                    id: 4,
                                    title: "msgSec3Cat3Gr1Qz1Ans4",
                                    weight: 0.25,
                                    selected: false,
                                },
                                {
                                    id: 5,
                                    title: "msgSec3Cat3Gr1Qz1Ans5",
                                    weight: 0,
                                    selected: false,
                                },
                            ]
                        },
                    ]
                },
                {
                    id: 26,
                    title: "msgSec3Cat3Gr2",
                    level: null,
                    info: "",
                    quizs: [
                        {
                            id: 31,
                            type_id: 2,
                            title: "msgSec3Cat3Gr2Qz1",
                            answers: [
                                {
                                    id: 1,
                                    title: "msgSec3Cat3Gr2Qz1Ans1",
                                    weight: 0.25,
                                    selected: false,
                                },
                                {
                                    id: 2,
                                    title: "msgSec3Cat3Gr2Qz1Ans2",
                                    weight: 0.25,
                                    selected: false,
                                },
                                {
                                    id: 3,
                                    title: "msgSec3Cat3Gr2Qz1Ans3",
                                    weight: 0.25,
                                    selected: false,
                                },
                                {
                                    id: 4,
                                    title: "msgSec3Cat3Gr2Qz1Ans4",
                                    weight: 0.25,
                                    selected: false,
                                },
                                {
                                    id: 5,
                                    title: "msgSec3Cat3Gr2Qz1Ans5",
                                    weight: 0,
                                    selected: false,
                                },
                            ]
                        },
                    ]
                },
                {
                    id: 27,
                    title: "msgSec3Cat3Gr3",
                    level: null,
                    info: "",
                    quizs: [
                        {
                            id: 32,
                            type_id: 2,
                            title: "msgSec3Cat3Gr3Qz1",
                            answers: [
                                {
                                    id: 1,
                                    title: "msgSec3Cat3Gr3Qz1Ans1",
                                    weight: 0.25,
                                    selected: false,
                                },
                                {
                                    id: 2,
                                    title: "msgSec3Cat3Gr3Qz1Ans2",
                                    weight: 0.25,
                                    selected: false,
                                },
                                {
                                    id: 3,
                                    title: "msgSec3Cat3Gr3Qz1Ans3",
                                    weight: 0.25,
                                    selected: false,
                                },
                                {
                                    id: 4,
                                    title: "msgSec3Cat3Gr3Qz1Ans4",
                                    weight: 0.25,
                                    selected: false,
                                },
                                {
                                    id: 5,
                                    title: "msgSec3Cat3Gr3Qz1Ans5",
                                    weight: 0,
                                    selected: false,
                                },
                            ]
                        },
                    ]
                },
            ]
        },
    ]
}

const sectionFour = {
    id: 4,
    title: "msgSection4",
    description_short: "msgSection4DescShort",
    description: "msgSection4Desc",
    image: sec_four,
    group_id: 1,
    categories: [
        {
            id: 1,
            title: "msgCat1",
            groups: [
                {
                    id: 38,
                    title: "msgSec4Cat1Gr1",
                    level: null,
                    info: "",
                    quizs: [
                        {
                            id: 43,
                            type_id: 1,
                            title: "msgSec4Cat1Gr1Qz1",
                            answers: [
                                {
                                    id: 1,
                                    title: "actionYes",
                                    weight: 1,
                                    selected: false,
                                },
                                {
                                    id: 2,
                                    title: "actionNo",
                                    weight: 0,
                                    selected: false,
                                },
                            ]
                        },
                    ]
                },
                {
                    id: 39,
                    title: "msgSec4Cat1Gr2",
                    level: null,
                    info: "",
                    quizs: [
                        {
                            id: 44,
                            type_id: 1,
                            title: "msgSec4Cat1Gr2Qz1",
                            answers: [
                                {
                                    id: 1,
                                    title: "actionYes",
                                    weight: 1,
                                    selected: false,
                                },
                                {
                                    id: 2,
                                    title: "actionNo",
                                    weight: 0,
                                    selected: false,
                                },
                            ]
                        },
                    ]
                },
            ]
        },

        {
            id: 2,
            title: "msgCat2",
            groups: [
                {
                    id: 36,
                    title: "msgSec4Cat2Gr1",
                    level: null,
                    info: "",
                    quizs: [
                        {
                            id: 41,
                            type_id: 1,
                            title: "msgSec4Cat2Gr1Qz1",
                            answers: [
                                {
                                    id: 1,
                                    title: "actionYes",
                                    weight: 1,
                                    selected: false,
                                },
                                {
                                    id: 2,
                                    title: "actionNo",
                                    weight: 0,
                                    selected: false,
                                },
                            ]
                        },
                    ]
                },
                {
                    id: 37,
                    title: "msgSec4Cat2Gr2",
                    level: null,
                    info: "",
                    quizs: [
                        {
                            id: 42,
                            type_id: 1,
                            title: "msgSec4Cat2Gr2Qz1",
                            answers: [
                                {
                                    id: 1,
                                    title: "actionYes",
                                    weight: 1,
                                    selected: false,
                                },
                                {
                                    id: 2,
                                    title: "actionNo",
                                    weight: 0,
                                    selected: false,
                                },
                            ]
                        },
                    ]
                },
            ]
        },
        {
            id: 3,
            title: "msgCat3",
            groups: [
                {
                    id: 31,
                    title: "msgSec4Cat3Gr1",
                    level: null,
                    info: "",
                    quizs: [
                        {
                            id: 36,
                            type_id: 1,
                            title: "msgSec4Cat3Gr1Qz1",
                            answers: [
                                {
                                    id: 1,
                                    title: "msgSec4Cat3Gr1Qz1Ans1",
                                    weight: 0.25,
                                    selected: false,
                                },
                                {
                                    id: 2,
                                    title: "msgSec4Cat3Gr1Qz1Ans2",
                                    weight: 0.5,
                                    selected: false,
                                },
                                {
                                    id: 3,
                                    title: "msgSec4Cat3Gr1Qz1Ans3",
                                    weight: 0.75,
                                    selected: false,
                                },
                                {
                                    id: 4,
                                    title: "msgSec4Cat3Gr1Qz1Ans4",
                                    weight: 1,
                                    selected: false,
                                },
                                {
                                    id: 5,
                                    title: "msgSec4Cat3Gr1Qz1Ans5",
                                    weight: 0,
                                    selected: false,
                                },
                            ]
                        },
                    ]
                },
                {
                    id: 32,
                    title: "msgSec4Cat3Gr2",
                    level: null,
                    info: "",
                    quizs: [
                        {
                            id: 37,
                            type_id: 2,
                            title: "msgSec4Cat3Gr2Qz1",
                            answers: [
                                {
                                    id: 1,
                                    title: "msgSec4Cat3Gr2Qz1Ans1",
                                    weight: 0.333,
                                    selected: false,
                                },
                                {
                                    id: 2,
                                    title: "msgSec4Cat3Gr2Qz1Ans2",
                                    weight: 0.333,
                                    selected: false,
                                },
                                {
                                    id: 3,
                                    title: "msgSec4Cat3Gr2Qz1Ans3",
                                    weight: 0.333,
                                    selected: false,
                                },
                                {
                                    id: 4,
                                    title: "msgSec4Cat3Gr2Qz1Ans4",
                                    weight: 0,
                                    selected: false,
                                },
                            ]
                        },
                    ]
                },
                {
                    id: 33,
                    title: "msgSec4Cat3Gr3",
                    level: null,
                    info: "",
                    quizs: [
                        {
                            id: 38,
                            type_id: 2,
                            title: "msgSec4Cat3Gr3Qz1",
                            answers: [
                                {
                                    id: 1,
                                    title: "msgSec4Cat3Gr3Qz1Ans1",
                                    weight: 0.333,
                                    selected: false,
                                },
                                {
                                    id: 2,
                                    title: "msgSec4Cat3Gr3Qz1Ans2",
                                    weight: 0.333,
                                    selected: false,
                                },
                                {
                                    id: 3,
                                    title: "msgSec4Cat3Gr3Qz1Ans3",
                                    weight: 0.333,
                                    selected: false,
                                },
                                {
                                    id: 4,
                                    title: "msgSec4Cat3Gr3Qz1Ans4",
                                    weight: 0,
                                    selected: false,
                                },
                            ]
                        },
                    ]
                },
                {
                    id: 34,
                    title: "msgSec4Cat3Gr4",
                    level: null,
                    info: "",
                    quizs: [
                        {
                            id: 39,
                            type_id: 2,
                            title: "msgSec4Cat3Gr4Qz1",
                            answers: [
                                {
                                    id: 1,
                                    title: "actionYes",
                                    weight: 1,
                                    selected: false,
                                },
                                {
                                    id: 2,
                                    title: "actionNo",
                                    weight: 0,
                                    selected: false,
                                },
                            ]
                        },
                    ]
                },
                {
                    id: 35,
                    title: "msgSec4Cat3Gr5",
                    level: null,
                    info: "",
                    quizs: [
                        {
                            id: 40,
                            type_id: 2,
                            title: "msgSec4Cat3Gr5Qz1",
                            answers: [
                                {
                                    id: 1,
                                    title: "actionYes",
                                    weight: 1,
                                    selected: false,
                                },
                                {
                                    id: 2,
                                    title: "actionNo",
                                    weight: 0,
                                    selected: false,
                                },
                            ]
                        },
                    ]
                },
            ]
        },
    ]
}

const sectionFive = {
    id: 5,
    title: "msgSection5",
    description_short: "msgSection5DescShort",
    description: "msgSection5Desc",
    image: sec_five,
    group_id: 1,
    categories: [
        {
            id: 1,
            title: "msgCat1",
            groups: [
                {
                    id: 46,
                    title: "msgSec5Cat1Gr1",
                    level: null,
                    info: "",
                    quizs: [
                        {
                            id: 51,
                            type_id: 2,
                            title: "msgSec5Cat1Gr1Qz1",
                            answers: [
                                {
                                    id: 1,
                                    title: "msgSec5Cat1Gr1Qz1Ans1",
                                    weight: 0.25,
                                    selected: false,
                                },
                                {
                                    id: 2,
                                    title: "msgSec5Cat1Gr1Qz1Ans2",
                                    weight: 0.25,
                                    selected: false,
                                },
                                {
                                    id: 3,
                                    title: "msgSec5Cat1Gr1Qz1Ans3",
                                    weight: 0.25,
                                    selected: false,
                                },
                                {
                                    id: 4,
                                    title: "msgSec5Cat1Gr1Qz1Ans4",
                                    weight: 0.25,
                                    selected: false,
                                },
                                {
                                    id: 5,
                                    title: "msgSec5Cat1Gr1Qz1Ans5",
                                    weight: 0,
                                    selected: false,
                                },
                            ]
                        },
                    ]
                },
            ]
        },
        {
            id: 2,
            title: "msgCat2",
            groups: [
                {
                    id: 42,
                    title: "msgSec5Cat2Gr1",
                    level: null,
                    info: "",
                    quizs: [
                        {
                            id: 47,
                            type_id: 1,
                            title: "msgSec5Cat2Gr1Qz1",
                            answers: [
                                {
                                    id: 1,
                                    title: "actionYes",
                                    weight: 1,
                                    selected: false,
                                },
                                {
                                    id: 2,
                                    title: "actionNo",
                                    weight: 0,
                                    selected: false,
                                },
                            ]
                        },
                    ]
                },
                {
                    id: 43,
                    title: "msgSec5Cat2Gr2",
                    level: null,
                    info: "",
                    quizs: [
                        {
                            id: 48,
                            type_id: 2,
                            title: "msgSec5Cat2Gr2Qz1",
                            answers: [
                                {
                                    id: 1,
                                    title: "msgSec5Cat2Gr2Qz1Ans1",
                                    weight: 0.333,
                                    selected: false,
                                },
                                {
                                    id: 2,
                                    title: "msgSec5Cat2Gr2Qz1Ans2",
                                    weight: 0.333,
                                    selected: false,
                                },
                                {
                                    id: 3,
                                    title: "msgSec5Cat2Gr2Qz1Ans3",
                                    weight: 0.333,
                                    selected: false,
                                },
                                {
                                    id: 4,
                                    title: "msgSec5Cat2Gr2Qz1Ans4",
                                    weight: 0,
                                    selected: false,
                                },
                            ]
                        },
                    ]
                },
                {
                    id: 44,
                    title: "msgSec5Cat2Gr3",
                    level: null,
                    info: "",
                    quizs: [
                        {
                            id: 49,
                            type_id: 2,
                            title: "msgSec5Cat2Gr3Qz1",
                            answers: [
                                {
                                    id: 1,
                                    title: "msgSec5Cat2Gr3Qz1Ans1",
                                    weight: 0.25,
                                    selected: false,
                                },
                                {
                                    id: 2,
                                    title: "msgSec5Cat2Gr3Qz1Ans2",
                                    weight: 0.25,
                                    selected: false,
                                },
                                {
                                    id: 3,
                                    title: "msgSec5Cat2Gr3Qz1Ans3",
                                    weight: 0.25,
                                    selected: false,
                                },
                                {
                                    id: 4,
                                    title: "msgSec5Cat2Gr3Qz1Ans4",
                                    weight: 0.25,
                                    selected: false,
                                },
                                {
                                    id: 5,
                                    title: "msgSec5Cat2Gr3Qz1Ans5",
                                    weight: 0.25,
                                    selected: false,
                                },
                            ]
                        },
                    ]
                },
                {
                    id: 45,
                    title: "msgSec5Cat2Gr4",
                    level: null,
                    info: "",
                    quizs: [
                        {
                            id: 50,
                            type_id: 1,
                            title: "msgSec5Cat2Gr4Qz1",
                            answers: [
                                {
                                    id: 1,
                                    title: "actionYes",
                                    weight: 1,
                                    selected: false,
                                },
                                {
                                    id: 2,
                                    title: "actionNo",
                                    weight: 0,
                                    selected: false,
                                },
                            ]
                        },
                    ]
                },
            ]
        },
        {
            id: 3,
            title: "msgCat3",
            groups: [
                {
                    id: 40,
                    title: "msgSec5Cat3Gr1",
                    level: null,
                    info: "",
                    quizs: [
                        {
                            id: 45,
                            type_id: 1,
                            title: "msgSec5Cat3Gr1Qz1",
                            answers: [
                                {
                                    id: 1,
                                    title: "actionYes",
                                    weight: 1,
                                    selected: false,
                                },
                                {
                                    id: 2,
                                    title: "actionNo",
                                    weight: 0,
                                    selected: false,
                                },
                            ]
                        },
                    ]
                },
                {
                    id: 41,
                    title: "msgSec5Cat3Gr2",
                    level: null,
                    info: "",
                    quizs: [
                        {
                            id: 46,
                            type_id: 1,
                            title: "msgSec5Cat3Gr2Qz1",
                            answers: [
                                {
                                    id: 1,
                                    title: "actionYes",
                                    weight: 1,
                                    selected: false,
                                },
                                {
                                    id: 2,
                                    title: "actionNo",
                                    weight: 0,
                                    selected: false,
                                },
                            ]
                        },
                    ]
                },
            ]
        },
    ]
}

const sectionSix = {
    id: 6,
    title: "msgSection6",
    description_short: "msgSection6DescShort",
    description: "msgSection6Desc",
    image: sec_six,
    group_id: 2,
    categories: [
        {
            id: 1,
            title: "msgCat1",
            groups: [
                {
                    id: 50,
                    title: "msgSec6Cat1Gr1",
                    level: null,
                    info: "",
                    quizs: [
                        {
                            id: 56,
                            type_id: 1,
                            title: "msgSec6Cat1Gr1Qz1",
                            answers: [
                                {
                                    id: 1,
                                    title: "actionYes",
                                    weight: 1,
                                    selected: false,
                                },
                                {
                                    id: 2,
                                    title: "actionNo",
                                    weight: 0,
                                    selected: false,
                                },
                            ]
                        },
                    ]
                }
            ]
        },
        {
            id: 2,
            title: "msgCat2",
            groups: [
                {
                    id: 48,
                    title: "msgSec6Cat2Gr1",
                    level: null,
                    info: "",
                    quizs: [
                        {
                            id: 54,
                            type_id: 1,
                            title: "msgSec6Cat2Gr1Qz1",
                            answers: [
                                {
                                    id: 1,
                                    title: "actionYes",
                                    weight: 1,
                                    selected: false,
                                },
                                {
                                    id: 2,
                                    title: "actionNo",
                                    weight: 0,
                                    selected: false,
                                },
                            ]
                        },
                    ]
                },
                {
                    id: 49,
                    title: "msgSec6Cat2Gr2",
                    level: null,
                    info: "",
                    quizs: [
                        {
                            id: 55,
                            type_id: 1,
                            title: "msgSec6Cat2Gr2Qz1",
                            answers: [
                                {
                                    id: 1,
                                    title: "actionYes",
                                    weight: 1,
                                    selected: false,
                                },
                                {
                                    id: 2,
                                    title: "actionNo",
                                    weight: 0,
                                    selected: false,
                                },
                            ]
                        },
                    ]
                }
            ]
        },
        {
            id: 3,
            title: "msgCat3",
            groups: [
                {
                    id: 47,
                    title: "msgSec6Cat3Gr1",
                    level: null,
                    info: "",
                    quizs: [
                        {
                            id: 52,
                            type_id: 1,
                            title: "msgSec6Cat3Gr1Qz1",
                            answers: [
                                {
                                    id: 1,
                                    title: "actionYes",
                                    weight: 1,
                                    selected: false,
                                },
                                {
                                    id: 2,
                                    title: "actionNo",
                                    weight: 0,
                                    selected: false,
                                },
                            ]
                        },
                        {
                            id: 53,
                            type_id: 1,
                            title: "msgSec6Cat3Gr1Qz2",
                            answers: [
                                {
                                    id: 1,
                                    title: "actionYes",
                                    weight: 1,
                                    selected: false,
                                },
                                {
                                    id: 2,
                                    title: "actionNo",
                                    weight: 0,
                                    selected: false,
                                },
                            ]
                        },
                    ]
                },
            ]
        },
    ]
}

const sectionSeven = {
    id: 7,
    title: "msgSection7",
    description_short: "msgSection7DescShort",
    description: "msgSection7Desc",
    image: sec_seven,
    group_id: 3,
    categories: [
        {
            id: 1,
            title: "msgCat1",
            groups: [
                {
                    id: 55,
                    title: "msgSec7Cat1Gr1",
                    level: null,
                    info: "",
                    quizs: [
                        {
                            id: 61,
                            type_id: 2,
                            title: "msgSec7Cat1Gr1Qz1",
                            answers: [
                                {
                                    id: 1,
                                    title: "msgSec7Cat1Gr1Qz1Ans1",
                                    weight: 0.2,
                                    selected: false,
                                },
                                {
                                    id: 2,
                                    title: "msgSec7Cat1Gr1Qz1Ans2",
                                    weight: 0.2,
                                    selected: false,
                                },
                                {
                                    id: 3,
                                    title: "msgSec7Cat1Gr1Qz1Ans3",
                                    weight: 0.2,
                                    selected: false,
                                },
                                {
                                    id: 4,
                                    title: "msgSec7Cat1Gr1Qz1Ans4",
                                    weight: 0.2,
                                    selected: false,
                                },
                                {
                                    id: 5,
                                    title: "msgSec7Cat1Gr1Qz1Ans5",
                                    weight: 0.2,
                                    selected: false,
                                },
                                {
                                    id: 6,
                                    title: "msgSec7Cat1Gr1Qz1Ans6",
                                    weight: 0,
                                    selected: false,
                                },
                            ]
                        },
                    ]
                },
                {
                    id: 56,
                    title: "msgSec7Cat1Gr2",
                    level: null,
                    info: "",
                    quizs: [
                        {
                            id: 62,
                            type_id: 2,
                            title: "msgSec7Cat1Gr2Qz1",
                            answers: [
                                {
                                    id: 1,
                                    title: "msgSec7Cat1Gr2Qz1Ans1",
                                    weight: 0.2,
                                    selected: false,
                                },
                                {
                                    id: 2,
                                    title: "msgSec7Cat1Gr2Qz1Ans2",
                                    weight: 0.2,
                                    selected: false,
                                },
                                {
                                    id: 3,
                                    title: "msgSec7Cat1Gr2Qz1Ans3",
                                    weight: 0.2,
                                    selected: false,
                                },
                                {
                                    id: 4,
                                    title: "msgSec7Cat1Gr2Qz1Ans4",
                                    weight: 0.2,
                                    selected: false,
                                },
                                {
                                    id: 5,
                                    title: "msgSec7Cat1Gr2Qz1Ans5",
                                    weight: 0.2,
                                    selected: false,
                                },
                                {
                                    id: 6,
                                    title: "msgSec7Cat1Gr2Qz1Ans6",
                                    weight: 0,
                                    selected: false,
                                },
                            ]
                        },
                    ]
                },
                {
                    id: 57,
                    title: "msgSec7Cat1Gr3",
                    level: null,
                    info: "",
                    quizs: [
                        {
                            id: 63,
                            type_id: 2,
                            title: "msgSec7Cat1Gr3Qz1",
                            answers: [
                                {
                                    id: 1,
                                    title: "msgSec7Cat1Gr3Qz1Ans1",
                                    weight: 0.333,
                                    selected: false,
                                },
                                {
                                    id: 2,
                                    title: "msgSec7Cat1Gr3Qz1Ans2",
                                    weight: 0.333,
                                    selected: false,
                                },
                                {
                                    id: 3,
                                    title: "msgSec7Cat1Gr3Qz1Ans3",
                                    weight: 0.333,
                                    selected: false,
                                },
                                {
                                    id: 4,
                                    title: "msgSec7Cat1Gr3Qz1Ans4",
                                    weight: 0,
                                    selected: false,
                                },
                            ]
                        },
                    ]
                },
            ]
        },
        {
            id: 2,
            title: "msgCat2",
            groups: [
                {
                    id: 53,
                    title: "msgSec7Cat2Gr1",
                    level: null,
                    info: "",
                    quizs: [
                        {
                            id: 59,
                            type_id: 2,
                            title: "msgSec7Cat2Gr1Qz1",
                            answers: [
                                {
                                    id: 1,
                                    title: "msgSec7Cat2Gr1Qz1Ans1",
                                    weight: 0.333,
                                    selected: false,
                                },
                                {
                                    id: 2,
                                    title: "msgSec7Cat2Gr1Qz1Ans2",
                                    weight: 0.333,
                                    selected: false,
                                },
                                {
                                    id: 3,
                                    title: "msgSec7Cat2Gr1Qz1Ans3",
                                    weight: 0.333,
                                    selected: false,
                                },
                                {
                                    id: 4,
                                    title: "msgSec7Cat2Gr1Qz1Ans4",
                                    weight: 0,
                                    selected: false,
                                },
                            ]
                        },
                    ]
                },
                {
                    id: 54,
                    title: "msgSec7Cat2Gr2",
                    level: null,
                    info: "",
                    quizs: [
                        {
                            id: 60,
                            type_id: 1,
                            title: "msgSec7Cat2Gr2Qz1",
                            answers: [
                                {
                                    id: 1,
                                    title: "actionYes",
                                    weight: 1,
                                    selected: false,
                                },
                                {
                                    id: 2,
                                    title: "actionNo",
                                    weight: 0,
                                    selected: false,
                                },
                            ]
                        },
                    ]
                }
            ],
        },
        {
            id: 3,
            title: "msgCat3",
            groups: [
                {
                    id: 51,
                    title: "msgSec7Cat3Gr1",
                    level: null,
                    info: "",
                    quizs: [
                        {
                            id: 57,
                            type_id: 1,
                            title: "msgSec7Cat3Gr1Qz1",
                            answers: [
                                {
                                    id: 1,
                                    title: "actionYes",
                                    weight: 1,
                                    selected: false,
                                },
                                {
                                    id: 2,
                                    title: "actionNo",
                                    weight: 0,
                                    selected: false,
                                },
                            ]
                        },
                    ]
                },
                {
                    id: 52,
                    title: "msgSec7Cat3Gr2",
                    level: null,
                    info: "",
                    quizs: [
                        {
                            id: 58,
                            type_id: 1,
                            title: "msgSec7Cat3Gr2Qz1",
                            answers: [
                                {
                                    id: 1,
                                    title: "actionYes",
                                    weight: 1,
                                    selected: false,
                                },
                                {
                                    id: 2,
                                    title: "actionNo",
                                    weight: 0,
                                    selected: false,
                                },
                            ]
                        },
                    ]
                }
            ],
        }
    ]
}

const sectionEight = {
    id: 8,
    title: "msgSection8",
    description_short: "msgSection8DescShort",
    description: "msgSection8Desc",
    image: sec_eight,
    group_id: 3,
    categories: [
        {
            id: 1,
            title: "msgCat1",
            groups: [
                {
                    id: 62,
                    title: "msgSec8Cat1Gr1",
                    level: null,
                    info: "",
                    quizs: [
                        {
                            id: 68,
                            type_id: 2,
                            title: "msgSec8Cat1Gr1Qz1",
                            answers: [
                                {
                                    id: 1,
                                    title: "msgSec8Cat1Gr1Qz1Ans1",
                                    weight: 0.333,
                                    selected: false,
                                },
                                {
                                    id: 2,
                                    title: "msgSec8Cat1Gr1Qz1Ans2",
                                    weight: 0.333,
                                    selected: false,
                                },
                                {
                                    id: 3,
                                    title: "msgSec8Cat1Gr1Qz1Ans3",
                                    weight: 0.333,
                                    selected: false,
                                },
                                {
                                    id: 4,
                                    title: "msgSec8Cat1Gr1Qz1Ans4",
                                    weight: 0,
                                    selected: false,
                                },
                            ]
                        },
                    ]
                },
                {
                    id: 63,
                    title: "msgSec8Cat1Gr2",
                    level: null,
                    info: "",
                    quizs: [
                        {
                            id: 69,
                            type_id: 2,
                            title: "msgSec8Cat1Gr2Qz1",
                            answers: [
                                {
                                    id: 1,
                                    title: "msgSec8Cat1Gr2Qz1Ans1",
                                    weight: 0.25,
                                    selected: false,
                                },
                                {
                                    id: 2,
                                    title: "msgSec8Cat1Gr2Qz1Ans2",
                                    weight: 0.25,
                                    selected: false,
                                },
                                {
                                    id: 3,
                                    title: "msgSec8Cat1Gr2Qz1Ans3",
                                    weight: 0.25,
                                    selected: false,
                                },
                                {
                                    id: 4,
                                    title: "msgSec8Cat1Gr2Qz1Ans4",
                                    weight: 0.25,
                                    selected: false,
                                },
                                {
                                    id: 5,
                                    title: "msgSec8Cat1Gr2Qz1Ans5",
                                    weight: 0,
                                    selected: false,
                                },
                            ]
                        },
                    ]
                },
            ]
        },
        {
            id: 2,
            title: "msgCat2",
            groups: [
                {
                    id: 59,
                    title: "msgSec8Cat2Gr1",
                    level: null,
                    info: "",
                    quizs: [
                        {
                            id: 65,
                            type_id: 2,
                            title: "msgSec8Cat2Gr1Qz1",
                            answers: [
                                {
                                    id: 1,
                                    title: "msgSec8Cat2Gr1Qz1Ans1",
                                    weight: 0.25,
                                    selected: false,
                                },
                                {
                                    id: 2,
                                    title: "msgSec8Cat2Gr1Qz1Ans2",
                                    weight: 0.25,
                                    selected: false,
                                },
                                {
                                    id: 3,
                                    title: "msgSec8Cat2Gr1Qz1Ans3",
                                    weight: 0.25,
                                    selected: false,
                                },
                                {
                                    id: 4,
                                    title: "msgSec8Cat2Gr1Qz1Ans4",
                                    weight: 0.25,
                                    selected: false,
                                },
                                {
                                    id: 5,
                                    title: "msgSec8Cat2Gr1Qz1Ans5",
                                    weight: 0,
                                    selected: false,
                                },
                            ]
                        },
                    ]
                },
                {
                    id: 60,
                    title: "msgSec8Cat2Gr2",
                    level: null,
                    info: "",
                    quizs: [
                        {
                            id: 66,
                            type_id: 2,
                            title: "msgSec8Cat2Gr2Qz1",
                            answers: [
                                {
                                    id: 1,
                                    title: "msgSec8Cat2Gr2Qz1Ans1",
                                    weight: 0.25,
                                    selected: false,
                                },
                                {
                                    id: 2,
                                    title: "msgSec8Cat2Gr2Qz1Ans2",
                                    weight: 0.25,
                                    selected: false,
                                },
                                {
                                    id: 3,
                                    title: "msgSec8Cat2Gr2Qz1Ans3",
                                    weight: 0.25,
                                    selected: false,
                                },
                                {
                                    id: 4,
                                    title: "msgSec8Cat2Gr2Qz1Ans4",
                                    weight: 0.25,
                                    selected: false,
                                },
                                {
                                    id: 5,
                                    title: "msgSec8Cat2Gr2Qz1Ans5",
                                    weight: 0.25,
                                    selected: false,
                                },
                            ]
                        },
                    ]
                },
                {
                    id: 61,
                    title: "msgSec8Cat2Gr3",
                    level: null,
                    info: "",
                    quizs: [
                        {
                            id: 67,
                            type_id: 1,
                            title: "msgSec8Cat2Gr3Qz1",
                            answers: [
                                {
                                    id: 1,
                                    title: "actionYes",
                                    weight: 1,
                                    selected: false,
                                },
                                {
                                    id: 2,
                                    title: "actionNo",
                                    weight: 0,
                                    selected: false,
                                },
                            ]
                        },
                    ]
                },
            ]
        },
        {
            id: 3,
            title: "msgCat3",
            groups: [
                {
                    id: 58,
                    title: "msgSec8Cat3Gr1",
                    level: null,
                    info: "",
                    quizs: [
                        {
                            id: 64,
                            type_id: 2,
                            title: "msgSec8Cat3Gr1Qz1",
                            answers: [
                                {
                                    id: 1,
                                    title: "msgSec8Cat3Gr1Qz1Ans1",
                                    weight: 0.333,
                                    selected: false,
                                },
                                {
                                    id: 2,
                                    title: "msgSec8Cat3Gr1Qz1Ans2",
                                    weight: 0.333,
                                    selected: false,
                                },
                                {
                                    id: 3,
                                    title: "msgSec8Cat3Gr1Qz1Ans3",
                                    weight: 0.333,
                                    selected: false,
                                },
                                {
                                    id: 4,
                                    title: "msgSec8Cat3Gr1Qz1Ans4",
                                    weight: 0,
                                    selected: false,
                                },
                            ]
                        },
                    ]
                },
            ]
        },
    ]
}

const sectionNine = {
    id: 9,
    title: "msgSection9",
    description_short: "msgSection9DescShort",
    description: "msgSection9Desc",
    image: sec_nine,
    group_id: 3,
    categories: [
        {
            id: 1,
            title: "msgCat1",
            groups: [
                {
                    id: 68,
                    title: "msgSec9Cat1Gr1",
                    level: null,
                    info: "",
                    quizs: [
                        {
                            id: 74,
                            type_id: 1,
                            title: "msgSec9Cat1Gr1Qz1",
                            answers: [
                                {
                                    id: 1,
                                    title: "actionYes",
                                    weight: 1,
                                    selected: false,
                                },
                                {
                                    id: 2,
                                    title: "actionNo",
                                    weight: 0,
                                    selected: false,
                                },
                            ]
                        },
                    ]
                },
                {
                    id: 69,
                    title: "msgSec9Cat1Gr2",
                    level: null,
                    info: "",
                    quizs: [
                        {
                            id: 75,
                            type_id: 1,
                            title: "msgSec9Cat1Gr2Qz1",
                            answers: [
                                {
                                    id: 1,
                                    title: "actionYes",
                                    weight: 1,
                                    selected: false,
                                },
                                {
                                    id: 2,
                                    title: "actionNo",
                                    weight: 0,
                                    selected: false,
                                },
                            ]
                        },
                    ]
                },
            ]
        },
        {
            id: 2,
            title: "msgCat2",
            groups: [
                {
                    id: 67,
                    title: "msgSec9Cat2Gr1",
                    level: null,
                    info: "",
                    quizs: [
                        {
                            id: 73,
                            type_id: 1,
                            title: "msgSec9Cat2Gr1Qz1",
                            answers: [
                                {
                                    id: 1,
                                    title: "actionYes",
                                    weight: 1,
                                    selected: false,
                                },
                                {
                                    id: 2,
                                    title: "actionNo",
                                    weight: 0,
                                    selected: false,
                                },
                            ]
                        },
                    ]
                },
            ]
        },
        {
            id: 3,
            title: "msgCat3",
            groups: [
                {
                    id: 64,
                    title: "msgSec9Cat3Gr1",
                    level: null,
                    info: "",
                    quizs: [
                        {
                            id: 70,
                            type_id: 2,
                            title: "msgSec9Cat3Gr1Qz1",
                            answers: [
                                {
                                    id: 1,
                                    title: "msgSec9Cat3Gr1Qz1Ans1",
                                    weight: 0.25,
                                    selected: false,
                                },
                                {
                                    id: 2,
                                    title: "msgSec9Cat3Gr1Qz1Ans2",
                                    weight: 0.25,
                                    selected: false,
                                },
                                {
                                    id: 3,
                                    title: "msgSec9Cat3Gr1Qz1Ans3",
                                    weight: 0.25,
                                    selected: false,
                                },
                                {
                                    id: 4,
                                    title: "msgSec9Cat3Gr1Qz1Ans4",
                                    weight: 0.25,
                                    selected: false,
                                },
                                {
                                    id: 5,
                                    title: "msgSec9Cat3Gr1Qz1Ans5",
                                    weight: 0,
                                    selected: false,
                                },
                            ]
                        },
                    ]
                },
                {
                    id: 65,
                    title: "msgSec9Cat3Gr2",
                    level: null,
                    info: "",
                    quizs: [
                        {
                            id: 71,
                            type_id: 1,
                            title: "msgSec9Cat3Gr2Qz1",
                            answers: [
                                {
                                    id: 1,
                                    title: "actionYes",
                                    weight: 1,
                                    selected: false,
                                },
                                {
                                    id: 2,
                                    title: "actionNo",
                                    weight: 0,
                                    selected: false,
                                },
                            ]
                        },
                    ]
                },
                {
                    id: 66,
                    title: "msgSec9Cat3Gr3",
                    level: null,
                    info: "",
                    quizs: [
                        {
                            id: 72,
                            type_id: 1,
                            title: "msgSec9Cat3Gr3Qz1",
                            answers: [
                                {
                                    id: 1,
                                    title: "actionYes",
                                    weight: 1,
                                    selected: false,
                                },
                                {
                                    id: 2,
                                    title: "actionNo",
                                    weight: 0,
                                    selected: false,
                                },
                            ]
                        },
                    ]
                },
            ]
        },
    ]
}

export const sectionData = [
    sectionOne,
    sectionTwo,
    sectionThree,
    sectionFour,
    sectionFive,
    sectionSix,
    sectionSeven,
    sectionEight,
    sectionNine,
]