import React, { useContext, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { UserData } from '../providers/UserProvider';
import { Button, Card, Container, ContainerFlex, TextLocalized } from '../styled/Styled';
import { getQuiz } from '../util/QuizUitl';

import Icon from '@mdi/react';
import { mdiChevronLeft } from '@mdi/js';
import { LanguageContext } from '../providers/LanguageProvider';

const LoadJsonView = () => {
    const [json, setJson] = useState("");
    const [nameIndic, setNameIndic] = useState("color-blue")

    const [results, setResults] = useState();

    const languageContext = useContext(LanguageContext);
    let history = useHistory();

    const loadJson = () => {
        if (json !== "") {
            setNameIndic("color-blue")

            let jsonObject = JSON.parse(json);
            let quiz = getQuiz();

            for (let i = 0; i < quiz.length; i++) {
                if (jsonObject.length > i) {
                    jsonObject[i].image = quiz[i].image;
                }
            }

            localStorage.setItem(UserData.quiz, JSON.stringify([...jsonObject]));
            setJson("");
            setResults("success");
        } else {
            setNameIndic("color-red")
        }
    }

    return <ContainerFlex width="12" justify="center" minheight="calc(100vh - 92px)">
        <ContainerFlex width="12" lg="8" xl="8" justify="center" >

            <Container width="12" textAlign="center" fontWeight="bold" fontSize="28px" fontColor="color-gray" children={<TextLocalized children="homeTitle" />} />
            <Card mv="16" minwidth="200px" height="5px" color="color-blue" radius="2px" />

            <ContainerFlex width="12" fontColor="color-black" align="center" pt="32">
                <Button onClick={() => history.replace("/")} flex={true} px="8" justify="center" align="center" color="color-blue" hoverColor="color-blue-dark" fontColor="color-white" radius="50%" children={<Icon path={mdiChevronLeft} size={1} />} />
                <Container ph="16" fontWeight="bold" children={<TextLocalized children="actionBack" />} />
            </ContainerFlex>
            <Card width="12" mt="32" border="solid" borderColor={nameIndic} borderWidth="2px">
                <textarea rows="10" value={json} onChange={e => setJson(e.target.value)} type="text" placeholder={languageContext.dictionary["msgJsondHolder"]} style={{ width: "100%", padding: "16px" }} />
            </Card>
            <Button onClick={loadJson} shadowOn={true} width="12" mv="32" pv="16" color="color-blue" hoverColor="color-blue-dark"
                fontColor="color-white" fontWeight="bold" textAlign="center"
                children={<TextLocalized children="actionLoadResults" />} />
            {
                results ?
                    <TextLocalized children={results === "success" ? "msgSuccessLoad" : "errorLoad"} />
                    : null
            }
        </ContainerFlex>
    </ContainerFlex>
};

export default LoadJsonView;