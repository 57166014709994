import React, { useContext, useState } from 'react';
import { useEffect } from 'react';
import { useHistory } from 'react-router';
import { Button, Card, Container, ContainerFlex, TextLocalized } from '../styled/Styled';

import Icon from '@mdi/react';
import { mdiChevronLeft } from '@mdi/js';

import { MyResponsiveRadar } from '../util/ChartUtil';
import { getQuiz } from '../util/QuizUitl';
import { LanguageContext } from '../providers/LanguageProvider';

const dataCatOne = [
    {
        "taste": "Trabajadores",
        "economic": 0,
        "ecological": 0,
        "social": 0
    },
    {
        "taste": "Gobernanza y estructura",
        "economic": 0,
        "ecological": 0,
        "social": 0
    },
    {
        "taste": "Recursos clave",
        "economic": 0,
        "ecological": 0,
        "social": 0
    },
    {
        "taste": "Actividades y procesos",
        "economic": 0,
        "ecological": 0,
        "social": 0
    },
    {
        "taste": "Cadena de suministro y aliados",
        "economic": 0,
        "ecological": 0,
        "social": 0
    }
]

const dataCatTwo = [
    {
        "taste": "Propuesta de valor",
        "economic": 0,
        "ecological": 0,
        "social": 0
    },
    {
        "taste": "Clientes y beneficiarios",
        "economic": 0,
        "ecological": 0,
        "social": 0
    },
    {
        "taste": "Stakeholders",
        "economic": 0,
        "ecological": 0,
        "social": 0
    },
    {
        "taste": "Espacios de relación",
        "economic": 0,
        "ecological": 0,
        "social": 0
    },
]

const dataProm = [
    {
        "taste": "Economic",
        "promedio": 0,
    },
    {
        "taste": "Ecological",
        "promedio": 0,
    },
    {
        "taste": "Social",
        "promedio": 0,
    },
]

const EvalView = () => {

    const [graphOne, setGraphOne] = useState([...dataCatOne]);
    const [graphTwo, setGraphTwo] = useState([...dataCatTwo]);
    const [graphProm, setGraphProm] = useState([...dataProm]);

    const [results, setResults] = useState();
    const [name, setName] = useState("");
    const [nameIndic, setNameIndic] = useState("color-blue")
    const languageContext = useContext(LanguageContext);

    let history = useHistory();

    const getCatName = (index) => index === 0 ? "economic" : index === 1 ? "social" : "ecological";

    useEffect(() => {
        let quiz = getQuiz();

        let graphOneAux = [...dataCatOne];
        let graphTwoAux = [...dataCatTwo];
        let graphPromAux = [...dataProm];

        quiz.map((block, i) => {
            block.categories.map((category, j) => {
                let numAnswers = 0;
                let numQuiz = 0;

                category.groups.map(group => {
                    //Add num quizs
                    numQuiz += group.quizs.length;
                    group.quizs.map(quiz => {
                        //Increment num answers
                        let selectedArray = quiz.answers.filter(answ => answ.selected);
                        if (quiz.type_id === 1) {
                            if (selectedArray.length > 0)
                                numAnswers += selectedArray[0].weight;
                        } else {
                            let value = selectedArray.reduce((ac, item) => ac + item.weight, 0);
                            numAnswers += value;
                        }

                        return quiz;
                    })
                    return group;
                });

                let result = (numAnswers / numQuiz * 100)

                //Block 3 - Economic
                if (i === 2 && j === 0) {
                    result = 100;
                }

                if (i < 5) {
                    graphOneAux[i][getCatName(j)] = Math.ceil(result);
                } else {
                    graphTwoAux[i - 5][getCatName(j)] = Math.ceil(result);
                }

                if (j === 1) {
                    graphPromAux[2].promedio = parseInt(graphPromAux[2].promedio) + result;
                } else if (j === 2) {
                    graphPromAux[1].promedio = parseInt(graphPromAux[1].promedio) + result;
                } else {
                    graphPromAux[0].promedio = parseInt(graphPromAux[0].promedio) + result;
                }

                return category;
            })
            return block;
        })

        graphPromAux[0].promedio = Math.ceil((graphPromAux[0].promedio / 9));
        graphPromAux[1].promedio = Math.ceil((graphPromAux[1].promedio / 9));
        graphPromAux[2].promedio = Math.ceil((graphPromAux[2].promedio / 9));

        setGraphOne(graphOneAux);
        setGraphTwo(graphTwoAux);
        setGraphProm(graphPromAux);
    }, [])

    const getGroupLevel = (index) => index ? languageContext.dictionary[index === 1 ? "msgLow" : index === 2 ? "msgMedium" : "msgHight"] : ""

    const sendResults = async () => {
        if (name !== "") {
            setNameIndic("color-blue")
            let quiz = getQuiz();

            let resultAux = "<div>"

            resultAux += `<h1>ID: ${name}</h1><br/><br/>`;

            quiz.map(block => {
                //Add block
                resultAux += `<h1>${languageContext.dictionary[block.title]}</h1>`;
                //Add category
                block.categories.map(category => {
                    resultAux += `<h2>${languageContext.dictionary[category.title]}</h2>`;
                    //Add group
                    category.groups.map(group => {
                        resultAux += `<h3>· ${languageContext.dictionary[group.title]} - [${getGroupLevel(group.level)}]</h3>`;
                        //Add quiz
                        group.quizs.map(quiz => {
                            resultAux += `<h5> - ${languageContext.dictionary[quiz.title]}</h5>`;
                            quiz.answers.map(answer => {
                                //Add answers
                                if (answer.selected) {
                                    resultAux += `<p>  ${languageContext.dictionary[answer.title]}</p>`;
                                }
                                return answer;
                            })
                            return quiz;
                        });
                        resultAux += `<h5>Comentario: ${group.info}</h5>`;
                        return group;
                    })
                    return category;
                })
                return block;
            });

            resultAux += "</div>";

            resultAux += "<div>"
            resultAux += `<br/><br/><h1>JSON:</h1><br/><br/>`;
            resultAux += JSON.stringify(quiz);
            resultAux += "</div>";

            let response = await fetch(`https://rscontract.cenfim.org/mail/sendResults.php`, {
                method: "POST",
                body: JSON.stringify({ results: resultAux })
            })

            setResults(response.ok ? "success" : "error");
            setName("");
        } else {
            setNameIndic("color-red")
        }
    }

    return (
        <ContainerFlex width="12" justify="center" minheight="calc(100vh - 92px)">

            <ContainerFlex width="12" lg="8" xl="8" justify="center" >
                <Container width="12" textAlign="center" fontWeight="bold" fontSize="28px" fontColor="color-gray" children={<TextLocalized children="homeTitle" />} />
                <Card mv="16" minwidth="200px" height="5px" color="color-blue" radius="2px" />

                <ContainerFlex width="12" fontColor="color-black" align="center" pt="32">
                    <Button onClick={() => history.goBack()} flex={true} px="8" justify="center" align="center" color="color-blue" hoverColor="color-blue-dark" fontColor="color-white" radius="50%" children={<Icon path={mdiChevronLeft} size={1} />} />
                    <Container ph="16" fontWeight="bold" children={<TextLocalized children="actionBack" />} />
                </ContainerFlex>

                <Card width="12" shadowOn={true} color="color-white" mt="32">
                    <Card width="12" px="16" fontSize="18px" textAlign="left" fontWeight="300" color="color-blue" fontColor="color-white" children={<TextLocalized children="msgEvalBlock" />} />
                    <ContainerFlex width="12" pv="16" justify="space-around">
                        <ContainerFlex width="12" height="500px">
                            <MyResponsiveRadar data={graphOne} colors={['#fda100', '#2d9613', '#19a7d7']} keys={['economic', 'ecological', 'social']} />
                        </ContainerFlex>

                        <ContainerFlex width="12" height="500px">
                            <MyResponsiveRadar data={graphTwo} colors={['#fda100', '#2d9613', '#19a7d7']} keys={['economic', 'ecological', 'social']} />
                        </ContainerFlex>
                    </ContainerFlex>
                </Card>

                <Card width="12" shadowOn={true} color="color-white" mt="32">
                    <Card width="12" px="16" fontSize="18px" textAlign="left" fontWeight="300" color="color-blue" fontColor="color-white" children={<TextLocalized children="msgEvalDimen" />} />
                    <ContainerFlex width="12" pv="16" justify="space-around">
                        <ContainerFlex width="12" height="500px">
                            <MyResponsiveRadar data={graphProm} colors={['#666666',]} keys={['promedio',]} />
                        </ContainerFlex>
                    </ContainerFlex>
                </Card>

                <Card width="12" mt="32" border="solid" borderColor={nameIndic} borderWidth="2px">
                    <input value={name} onChange={e => setName(e.target.value)} type="text" placeholder={languageContext.dictionary["msgIdHolder"]} style={{ width: "100%", padding: "16px" }} />
                </Card>
                <Button onClick={sendResults} shadowOn={true} width="12" mv="32" pv="16" color="color-blue" hoverColor="color-blue-dark"
                    fontColor="color-white" fontWeight="bold" textAlign="center"
                    children={<TextLocalized children="actionSendResults" />} />
                {
                    results ?
                        <TextLocalized children={results === "success" ? "msgSuccessMail" : "errorMail"} />
                        : null
                }
            </ContainerFlex>
        </ContainerFlex>
    );
};

export default EvalView;